import * as types from 'store/types/users';

export const getUsersByOrganizationId = (organizationId) => ({
  type: types.GET_BY_ORGANIZATION_ID,
  organizationId
});

export const setUsers = (entries) => ({
  type: types.SET_USERS,
  entries
});

export const loginAs = (userId) => ({
  type: types.LOGIN_AS,
  userId
});

export const resetPassword = (newPassword, userId) => ({
  type: types.RESET_PASSWORD,
  newPassword,
  userId
});

export const setLoadingStatus = (status) => ({
  type: types.SET_LOADING_STATUS,
  status
});

export const setError = (error) => ({
  type: types.SET_ERROR,
  error
});

export const reset = () => ({
  type: types.RESET,
});
