export const documentStates = Object.freeze({
  NEW: 'new',
  INPROGRESS: 'inprogress',
  DONE: 'done',
});

export const questionTypes = Object.freeze({
  STANDARD: 'standard',
  DOCUMENT: 'document'
});

export const questionCategories = Object.freeze({
  A: 'A',
  B: 'B',
  C: 'C',
  D: 'D',
  E: 'E',
  F: 'F',
  G: 'G',
  H: 'H',

  U: 'U', // stands for unique questions
});

export const annotationModes = Object.freeze({
  DEFAULT: 'DEFAULT',
  ANSWER_CATEGORY_MODE: 'ANSWER_CATEGORY_MODE',
});

export const answerCategories = Object.freeze({
  SHORT: 'SHORT',
  LONG: 'LONG',
  YES: 'YES',
  NO: 'NO',
  OTHER: 'OTHER',
  NOT_UNDERSTOOD: 'NOT_UNDERSTOOD',
  NOT_GIVEN: 'NOT_GIVEN',
});

export const answerCategoriesText = Object.freeze({
  [answerCategories.SHORT]: 'Short, concise answer',
  [answerCategories.LONG]: 'Long answer (including answers spanning multiple sentences, tables and lists)',
  [answerCategories.YES]: 'Yes answer',
  [answerCategories.NO]: 'No answer',
  [answerCategories.OTHER]: 'Other',
  [answerCategories.NOT_UNDERSTOOD]: 'Unclear question',
  [answerCategories.NOT_GIVEN]: 'Answer is not given'
});
