import React, { PureComponent } from 'react';
import { Form, Input, Button, Checkbox } from 'antd';
import PropTypes from 'prop-types';

import styles from './styles.module.scss';

class RegistrationForm extends PureComponent {
  static propTypes = {
    form: PropTypes.object,
    error: PropTypes.string,
    onSubmit: PropTypes.func,
    onClearError: PropTypes.func,
    onOpenModal: PropTypes.func,
  };

  state = {
    confirmDirty: false,
    autoCompleteResult: [],
  };

  handleConfirmBlur = e => {
    const { value } = e.target;

    this.setState({ confirmDirty: this.state.confirmDirty || !!value });
  };

  compareToFirstPassword = (rule, value, callback) => {
    const { form } = this.props;

    if (value && value !== form.getFieldValue('password')) {
      callback('Two passwords that you enter is inconsistent!');
    } else {
      callback();
    }
  };

  validateToNextPassword = (rule, value, callback) => {
    const { form } = this.props;

    if (value && this.state.confirmDirty) {
      form.validateFields(['confirm'], { force: true });
    }

    callback();
  };

  handleSubmit = (e) => {
    e.preventDefault();

    this.props.form.validateFields((err, values) => {
      const agreementCheckBox = values.agreement;

      if (!err && agreementCheckBox) {
        this.props.onSubmit(values);
      }
    });
  };

  render() {
    const {
      error,
      form: {
        getFieldDecorator
      },
      form,
      onOpenModal
    } = this.props;

    const isAgreeWithAgreement = form.getFieldValue('agreement');

    return (
      <Form onSubmit={this.handleSubmit}>
        <Form.Item>
          {getFieldDecorator('first_name', {
            rules: [{ required: true, message: 'Please input your first name!' }],
          })(<Input placeholder="First name" type="text" autoFocus />)}
        </Form.Item>

        <Form.Item>
          {getFieldDecorator('last_name', {
            rules: [{ required: true, message: 'Please input your last name!' }],
          })(<Input placeholder="Last name" type="text" />)}
        </Form.Item>

        <Form.Item>
          {getFieldDecorator('organization_name', {
            rules: [{ required: true, message: 'Please input organization name!' }],
          })(<Input placeholder="Organization name" type="text" />)}
        </Form.Item>

        <Form.Item>
          {getFieldDecorator('email', {
            rules: [
              { type: 'email', message: 'Email is not valid!' },
              { required: true, message: 'Please input your email!' },
            ],
          })(<Input placeholder="Email" type="email" />)}
        </Form.Item>

        <Form.Item hasFeedback>
          {getFieldDecorator('password', {
            rules: [
              { required: true, message: 'Please input your password!' },
              { validator: this.validateToNextPassword }
            ]
          })(<Input.Password placeholder="Password" type="password" />)}
        </Form.Item>

        <Form.Item hasFeedback>
          {getFieldDecorator('confirm_password', {
            rules: [
              { required: true, message: 'Please confirm your password!' },
              { validator: this.compareToFirstPassword },
            ],
          })(<Input.Password placeholder="Repeat password" onBlur={this.handleConfirmBlur} />)}
        </Form.Item>

        <Form.Item>
          {getFieldDecorator('agreement', { initialValue: false })(
            <div className={styles.checkBoxWrapper}>
              <Checkbox>
                I agree
                <a href="/" onClick={(evt) => onOpenModal(evt, 'Terms and conditions')}> terms and conditions </a>
                and
                <a href="/" onClick={(evt) => onOpenModal(evt, 'Privacy statement')}> privacy statement</a>.
              </Checkbox>
            </div>
          )}
        </Form.Item>

        <Button
          block
          disabled={!isAgreeWithAgreement}
          type="primary"
          onClick={this.handleSubmit}
        >
          Registration
        </Button>

        { error && <div className={styles.error}>{error}</div>}
      </Form>
    );
  }
}

export default Form.create({
  onFieldsChange: (props) => {
    if (props.error) {
      props.onClearError();
    }
  }
})(RegistrationForm);