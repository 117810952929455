import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Link, Redirect } from 'react-router-dom';

import { Loader } from 'components/common';
import { WrappedLoginForm as LoginForm } from './LoginForm';
import * as authActions from 'store/actions/auth';
import * as superAdminActions from 'store/actions/superAdmin';
import links from 'config/links';
import styles from './styles.module.scss';
import AuthWrapper from '../AuthWrapper';

export class Login extends PureComponent {
  static propTypes = {
    token: PropTypes.string,
    error: PropTypes.string,
    authActions: PropTypes.object,
    superAdminActions: PropTypes.object,
    isLoading: PropTypes.bool,
  };

  render() {
    const {
      token,
      error,
      authActions,
      isSuperAdminCreated,
      isLoading
    } = this.props;

    if (token) {
      return <Redirect to="/" />;
    }

    // redirect to createSuperAdmin page if super admin was not created yet.
    if (!isSuperAdminCreated) {
      return <Redirect to={links.createSuperAdmin} />;
    }

    return (
      <AuthWrapper title="Haystack Annotation Tool">
        <Loader loading={isLoading}>
          <LoginForm
            onSubmit={authActions.login}
            onClearError={authActions.resetError}
            error={error}
          />

          <p className={styles.linkToRegistration}>
            Not registered? Please sign up <Link to={links.registration}>here</Link>.
          </p>
        </Loader>
      </AuthWrapper>
    );
  }

  componentDidMount() {
    // check if super admin already created.
    this.props.superAdminActions.checkIfSuperAdminCreated();
  }

  componentWillUnmount () {
    this.props.authActions.resetError();
  }
}

export default connect(
  ({ auth, superAdmin }) => ({
    token: auth.token,
    error: auth.error,
    isLoading: superAdmin.isLoading,
    isSuperAdminCreated: superAdmin.isSuperAdminCreated,
  }),
  dispatch => ({
    authActions: bindActionCreators(authActions, dispatch),
    superAdminActions: bindActionCreators(superAdminActions, dispatch)
  })
)(Login);
