import { all, put, takeLatest, takeEvery, call } from 'redux-saga/effects';
import { message } from 'antd';
import * as api from 'store/sagas/api';
import * as types from 'store/types/users';
import * as actions from 'store/actions/users';
import * as authActions from 'store/actions/auth';
import * as userActions from 'store/actions/user';
import { setToken } from 'store/sagas/helpers/localStorage';
import history from './../../history';

export function* getUsersByOrganizationId({ organizationId }) {
  yield put(actions.setLoadingStatus(true));

  try {
    const users = yield api.get(`/superadmin/organizations/${ organizationId }/users`);

    yield put(actions.setUsers(users));
  } catch(error) {
    yield put(actions.setError(error.message));
  }

  yield put(actions.setLoadingStatus(false));
}

export function* loginAs({ userId }) {
  yield put(actions.setLoadingStatus(true));

  try {
    const { userInfo, token } = yield api.get(`/superadmin/loginAs/${ userId }`);

    yield setToken(token);
    yield put(authActions.setAuthData(token));
    yield put(userActions.setInfo(userInfo));
    yield call(history.push, '/');
    message.success(`You have been logged in as: ${ userInfo.email }`);
  } catch(error) {
    yield put(actions.setError(error.message));
    message.error(error.message);
  }

  yield put(actions.setLoadingStatus(false));
}

export function* resetPassword({ newPassword: passwordToUpdate, userId }) {
  yield put(actions.setLoadingStatus(true));

  try {
    const { newPassword } = yield api.post(`/user/${ userId }/reset_password`, { newPassword: passwordToUpdate });

    message.success(`Password changed successfully: ${ newPassword }`);
  } catch(error) {
    message.error(error.message);
  }

  yield put(actions.setLoadingStatus(false));
}

export default function* () {
  yield all([
    yield takeLatest(types.GET_BY_ORGANIZATION_ID, getUsersByOrganizationId),
    yield takeLatest(types.LOGIN_AS, loginAs),
    yield takeEvery(types.RESET_PASSWORD, resetPassword),
  ]);
}
