import React, { PureComponent, Fragment } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Redirect } from 'react-router-dom';
import links from 'config/links';
import * as userActions from 'store/actions/user';
import * as projectsActions from 'store/actions/projects';
import { Loader } from 'components/common';

class Provider extends PureComponent {

  static propTypes = {
    token: PropTypes.string,
    user: PropTypes.object,
    userActions: PropTypes.object,
    projectsActions: PropTypes.object
  }

  componentDidMount () {
    const { token, user, userActions } = this.props;

    if (token && !user.id) {
      userActions.getInfo();
    }
  }

  componentDidUpdate (prevProps) {
    const { token, user, userActions } = this.props;
    // if got a token and still have no user info
    if (!prevProps.token && token && !user.id) {
      userActions.getInfo();
    }

    if (!prevProps.user.id && user.id) {
      this.props.projectsActions.get();
    }
  }

  render () {
    const { token, user } = this.props;

    if (!token) {
      return <Redirect to={links.login} />;
    }

    if (!user.id || user.isLoading) {
      return <Loader fullSized size={30} />;
    }

    return (
      <Fragment>
        { this.props.children }
      </Fragment>
    );
  }
}

export default connect(
  (state) => ({
    token: state.auth.token,
    user: state.user,
  }),
  dispatch => ({
    userActions: bindActionCreators(userActions, dispatch),
    projectsActions: bindActionCreators(projectsActions, dispatch),
  })
)(Provider);
