
let url = process.env.REACT_APP_API || "/api";
const version = process.env.REACT_APP_VERSION || 'unknown';

if (
  window.location.host.indexOf("127.0.0.1") > -1 ||
  window.location.host.indexOf("localhost") > -1
) {
  url = process.env.REACT_APP_API_DEV || url;
}

console.log(`[deepannoate frontend] version [${version}] with backend on [${url}]`);
export const baseUrl = url;
