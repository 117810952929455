import * as types from 'store/types/user';

export const getInfo = () => ({
  type: types.GET_INFO,
});

export const setInfo = (payload) => ({
  type: types.SET_INFO,
  payload
});

export const setLoadingStatus = (status) => ({
  type: types.SET_LOADING_STATUS,
  status
});

export const reset = () => ({
  type: types.RESET,
});
