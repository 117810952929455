import { all, put, takeLatest, takeEvery } from 'redux-saga/effects';
import * as api from 'store/sagas/api';
import * as types from 'store/types/organization';
import * as actions from 'store/actions/organization';

export function* addUserToOrganization({ userInfo }) {
  yield put(actions.setLoadingStatus(true));

  try {
    const newUser = yield api.post('/organization/user', { userInfo });

    yield put(actions.setNewUser(newUser));
  } catch(error) {
    yield put(actions.setError(error.message));
  }

  yield put(actions.setLoadingStatus(false));
}

export function* getUsers() {
  yield put(actions.setLoadingStatus(true));

  try {
    const users = yield api.get('/organization/users');

    yield put(actions.setUsers(users));
  } catch(error) {
    yield put(actions.setError(error.message));
  }

  yield put(actions.setLoadingStatus(false));
}

export default function* () {
  yield all([
    yield takeLatest(types.GET_USERS, getUsers),
    yield takeEvery(types.ADD_NEW_USER_TO_ORGANIZATION, addUserToOrganization),
  ]);
}
