import React, { PureComponent } from 'react';
import { Route, Redirect, Switch } from 'react-router-dom';

import Organizations from 'components/Organizations';
import Organization from 'components/Organization';
import links from 'config/links';
import NotFound from 'components/NotFound';

class SuperAdminRoutes extends PureComponent {
  render () {
    return (
      <Switch>
        <Redirect exact from="/" to={links.organizations} />
        <Route path={links.organizations} exact component={Organizations} />
        <Route path={links.organization} exact component={Organization} />
        <Route component={NotFound} />
      </Switch>
    );
  }
}

export default SuperAdminRoutes;
