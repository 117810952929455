export default {
  login: '/login',
  registration: '/registration',
  userManagement: '/user_management',
  createSuperAdmin: '/create_super_admin',

  // user's routes
  projects: '/projects',
  project: '/projects/:projectId',
  document: '/projects/:projectId/document/:documentId',
  questions: '/projects/:projectId/questions',
  answers: '/projects/:projectId/labels',
  importDocuments: '/projects/:projectId/import_documents',
  importQuestions: '/projects/:projectId/import_questions',

  // super admin's routes
  organization: '/admin/organizations/:id',
  organizations: '/admin/organizations',
};
