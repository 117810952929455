import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import { Scrollbars } from 'react-custom-scrollbars';
import { Tabs, Button, Menu, Dropdown } from 'antd';
import { hexToRGBA } from 'core/utils/color';
import { answerCategories, questionCategories } from 'core/constants/types';
import styles from './styles.module.scss';


export class Questions extends PureComponent {
  constructor (props) {
    super(props);

    this.state = {
      currentQuestion: null,
    };
  }

  static propTypes = {
    title: PropTypes.string,
    itemsMap: PropTypes.object,
    customItems: PropTypes.array,
    maxListHeight: PropTypes.string,
    activeId: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.number,
    ]),
    activeTab: PropTypes.string,
    answeredQuestionsIds: PropTypes.array,
    showEditButtons: PropTypes.bool,
    disabled: PropTypes.bool,
    onTabChange: PropTypes.func,
    onSelect: PropTypes.func,
    onEdit: PropTypes.func,
    onDelete: PropTypes.func,
    onAnswer: PropTypes.func,
    onRemoveAnswers: PropTypes.func,

    // custom mode
    isCustomModeActive: PropTypes.bool,
    onCustomModeOpen: PropTypes.func,
    onCustomModeClose: PropTypes.func,
  }

  static defaultProps = {
    itemsMap: [],
    customItems: [],
    maxListHeight: 'auto',
    title: null,
    answeredQuestionsIds: [],
    disabled: false,
    showEditButtons: false,
    onTabChange: () => {},
    onSelect: () => {},
    onEdit: () => {},
    onDelete: () => {},
    onAnswer: () => {},
    onRemoveAnswers: () => {},

    // custom mode
    isCustomModeActive: false,
    onCustomModeOpen: () => {},
    onCustomModeClose: () => {},
  }

  handleQuestionSelect = (item, index) => () => {
    this.props.onSelect(item, index);
  }

  handleQuestionMenuToggle = (id) => (visible) => {
    this.setState({ currentQuestion: visible ? id : null })
  }

  resetCurrentActiveQuestion = () => {
    this.setState({ currentQuestion: null })
  }

  handleQuestionMenuToggle = (id) => (visible) => {
    this.setState({ currentQuestion: visible ? id : null })
  }

  handleEmptyAnswerSend = (id, answerCategory) => () => {
    const answer = {
      answer_category: answerCategory,
      start_offset: null,
      end_offset: null,
      selected_text: null
    };

    this.props.onAnswer(id, answer);
  }

  handleRemoveAnswers = (id) => () => {
    this.props.onRemoveAnswers(id);
  }

  handleQuestionEdit = (question) => () => {
    this.props.onEdit(question);
  }

  handleQuestionDelete = (question) => () => {
    this.props.onDelete(question);
  }

  renderQuestion = (item, i) => {
    const { activeId, disabled } = this.props;
    const isSelected = item.id === activeId;
    const isAnswered = this.props.answeredQuestionsIds.includes(item.id);
    const withNegativeAnswer = [answerCategories.NOT_GIVEN, answerCategories.NOT_UNDERSTOOD].includes(item.answer_category);
    const isActive = item.id === this.state.currentQuestion;

    const menu = item.category === questionCategories.U ? (
      <Menu onClick={this.resetCurrentActiveQuestion}>
        <Menu.Item onClick={this.handleQuestionEdit(item)}>
          <div>Edit</div>
        </Menu.Item>
        <Menu.Item onClick={this.handleQuestionDelete(item)}>
          <div>Delete</div>
        </Menu.Item>
        <Menu.Divider />
        <Menu.Item onClick={this.handleEmptyAnswerSend(item.id, answerCategories.NOT_UNDERSTOOD)}>
          <div>I don't understand the question</div>
        </Menu.Item>
        <Menu.Item onClick={this.handleRemoveAnswers(item.id)}>
          <div>Remove my answers</div>
        </Menu.Item>
      </Menu>

    ) : (
      <Menu onClick={this.resetCurrentActiveQuestion}>
        <Menu.Item onClick={this.handleEmptyAnswerSend(item.id, answerCategories.NOT_GIVEN)}>
          <div>Answer is not given</div>
        </Menu.Item>
        <Menu.Item onClick={this.handleEmptyAnswerSend(item.id, answerCategories.NOT_UNDERSTOOD)}>
          <div>I don't understand the question</div>
        </Menu.Item>
        <Menu.Item onClick={this.handleRemoveAnswers(item.id)}>
          <div>Remove my answers</div>
        </Menu.Item>
      </Menu>
    );

    return (
      <div
        key={item.id}
        className={cn(
          styles.question,
          { [styles.isActive]: isActive },
          { [styles.isSelected]: isSelected },
          { [styles.isAnswered]: isAnswered },
          { [styles.withNegativeAnswer]: withNegativeAnswer },
        )}
        onClick={this.handleQuestionSelect(item, i)}
      >
        <span
          className={styles.questionColor}
          style={{ backgroundColor: hexToRGBA(item.color, 0.36) }}
        />

        <div className={styles.questionTitle}>{ item.text }</div>

        <span className={styles.questionKey}>
          { i < 9 ? i + 1 : '' }
        </span>

        {
          // hide buttons if document is done and disabled
          !disabled && (
            <div className={styles.buttons} onClick={(e) => e.stopPropagation()}>
              <Dropdown
                overlay={menu}
                trigger={['click']}
                placement="bottomRight"
                onVisibleChange={this.handleQuestionMenuToggle(item.id)}
              >
                <Button shape="circle" size="small">
                  <i className="icon-edit" />
                </Button>
              </Dropdown>
            </div>
          )
        }
      </div>
    );
  }

  renderCustomQuestionHint = () => {
    return (
      <div className={styles.hint}>
        <i className="icon-info" />

        <div className={styles.hintContent}>
          <div className={styles.hintTitle}>Adding Custom Question</div>
          <p>Please select part of the text to add custom annotation</p>
          <Button size="small" ghost className={styles.cancelQuestionButton} onClick={this.props.onCustomModeClose}>
            Cancel
          </Button>
        </div>
      </div>
    );
  }

  render() {
    const { title, itemsMap, customItems, activeTab, maxListHeight,
      disabled, isCustomModeActive, onCustomModeOpen, onTabChange } = this.props;
    const standardEntries = Object.entries(itemsMap);
    const sortedEntries = standardEntries.sort((a, b) => a[0].charCodeAt() - b[0].charCodeAt());

    return (
      <div className={styles.wrapper}>
        { title && <div className={styles.title}>{title}</div> }

        <Tabs
          activeKey={activeTab}
          tabBarStyle={{ padding: '0 8px' }}
          tabBarGutter={8}
          size="small"
          onChange={onTabChange}
        >
          {
            sortedEntries.map((item) => {
              const itemsKey = item[0];
              const items = item[1];

              // "U" tab questions are processed separately below
              if (itemsKey === 'U') return null;

              return (
                <Tabs.TabPane tab={itemsKey} key={itemsKey}>
                  <Scrollbars
                    autoHeight
                    autoHeightMax={maxListHeight}
                  >
                    {
                      items.length
                        ? items.map((item, i) => this.renderQuestion(item, i))
                        : <div className={styles.placeholder}>No data</div>
                    }
                  </Scrollbars>
                </Tabs.TabPane>
              );
            })
          }


          <Tabs.TabPane tab="U" key="U">

            {
              customItems.length ? (

                <Scrollbars
                  autoHeight
                  autoHeightMax={maxListHeight}
                >
                  {
                    customItems.map((item, i) => this.renderQuestion(item, i))
                  }
                </Scrollbars>
              ) : <div className={styles.emptyDataMessage}>No questions prepared yet. Please use the Question tab to add questions for your project.</div>
            }

            <div className={styles.customQuestion}>
              {
                !isCustomModeActive
                  ? (
                    <Button
                      disabled={disabled}
                      onClick={onCustomModeOpen}
                      className={styles.customQuestionButton}
                    >
                      ADD CUSTOM QUESTION
                    </Button>
                  )
                  : this.renderCustomQuestionHint()
              }
            </div>

          </Tabs.TabPane>
        </Tabs>
      </div>
    );
  }
}

export default Questions;
