import { all, put, takeEvery, select, delay } from 'redux-saga/effects';
import { message } from 'antd';
import * as api from 'store/sagas/api';
import * as types from 'store/types/imports';
import * as actions from 'store/actions/imports';

export function* uploadDocuments (id, files) {
  const { isUploading } = yield select(state => state.imports.documents);
  let filesCount = 0;
  let uploadedFiles = 0;
  let uploadedSize = 0;

  if (isUploading) return;

  yield put(actions.setDocumentsUploadingStatus(true));

  for (const file of files) {
    try {
      yield api.upload(`/projects/${id}/documents`, file.name, file);

      filesCount += 1;
      uploadedFiles += 1;
      uploadedSize += file.size;

      yield put(actions.setDocumentsUploadProgress({ uploadedSize: uploadedSize, completedFiles: filesCount }));
      yield delay(400); // to prevent blinking
    } catch (error) {
      message.error(`${file.name} was not uploaded, ${error.message}`);
    }
  }

  yield delay(600);
  message.success(`${uploadedFiles} ${uploadedFiles > 1 ? 'files were' : 'file was'} successfully uploaded.`);
  yield put(actions.resetDocuments());
}

export function* uploadDocumentsCsv (id, file) {
  const { isUploading } = yield select(state => state.imports.documentsCsv);

  if (isUploading) return;

  yield put(actions.setDocumentsCsvUploadingStatus(true));

  try {
    const { result } = yield api.upload('/documents/csvupload', 'csvfile', file, file.name, { project_id: id });
    if (result.errors.length) {
      message.error(
        `Some errors happened during upload,
        ${result.totalRows - result.errors.length} of ${result.totalRows} ${result.totalRows > 1 ? 'documents were' : 'document was'} uploaded.`
      );

      yield put(actions.setDocumentsCsvErrors(result.errors));
    } else {
      yield put(actions.resetDocumentsCsv());
      message.success(`${result.succesfullyProcessed} ${result.succesfullyProcessed > 1 ? 'documents were' : 'document was'} successfully uploaded.`);
    }

  } catch (error) {
    message.error(error.message);
  }

  yield put(actions.setDocumentsCsvUploadingStatus(false));

}

export function* uploadQuestionsCsv (id, file) {
  const { isUploading } = yield select(state => state.imports.questionsCsv);

  if (isUploading) return;

  yield put(actions.setQuestionsCsvUploadingStatus(true));

  try {
    const { result } = yield api.upload('/questions/csvupload', 'csvfile', file, file.name, { project_id: id });

    if (result.errors.length) {
      message.error(
        `Some errors happened during upload,
        ${result.totalRows - result.errors.length} of ${result.totalRows} ${result.totalRows > 1 ? 'questions were' : 'question was'} uploaded.`
      );

      yield put(actions.setQuestionsCsvErrors(result.errors));
    } else {
      yield put(actions.resetQuestionsCsv());
      message.success(`${result.succesfullyProcessed} ${result.succesfullyProcessed > 1 ? 'questions were' : 'question was'} successfully uploaded.`);
    }

  } catch (error) {
    message.error(error.message);
  }

  yield put(actions.setQuestionsCsvUploadingStatus(false));

}


export default function* () {
  yield all([
    yield takeEvery(types.UPLOAD_DOCUMENTS, ({ projectId, files }) => uploadDocuments(projectId, files)),
    yield takeEvery(types.UPLOAD_DOCUMENTS_CSV, ({ projectId, file }) => uploadDocumentsCsv(projectId, file)),
    yield takeEvery(types.UPLOAD_QUESTIONS_CSV, ({ projectId, file }) => uploadQuestionsCsv(projectId, file)),
  ]);
}
