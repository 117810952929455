import React, { PureComponent } from 'react';
import { Route, Redirect, Switch } from 'react-router-dom';
import UserManagement from 'components/UserManagement';
import links from 'config/links';
import Projects from 'components/Projects';
import Documents from 'components/Documents';
import Document from 'components/Document';
import StandardQuestions from 'components/StandardQuestions';
import Answers from 'components/Answers';
import DocumentsImport from 'components/DocumentsImport';
import QuestionsImport from 'components/QuestionsImport';
import NotFound from 'components/NotFound';

class UserRoutes extends PureComponent {
  render () {
    return (
      <Switch>
        <Redirect exact from="/" to={links.projects} />
        <Route path={links.userManagement} component={UserManagement} />
        <Route exact path={links.projects} component={Projects} />
        <Route exact path={links.project} component={Documents} />
        <Route exact path={links.document} component={Document} />
        <Route exact path={links.questions} component={StandardQuestions} />
        <Route exact path={links.answers} component={Answers} />
        <Route exact path={links.importDocuments} component={DocumentsImport} />
        <Route exact path={links.importQuestions} component={QuestionsImport} />
        <Route component={NotFound} />
      </Switch>
    );
  }
}

export default UserRoutes;
