import { compile } from 'path-to-regexp';

import links from 'config/links';

// createLink function to create dynamic links
// example of usage: <Link to={createLink('projects', { id: 1, param2: 2 })}>...</Link>
export const createLink = (key, params = {}) => {
  try {
    const compiler = compile(links[key]);
    return compiler(params);
  } catch (e) {
    // eslint-disable-next-line no-console
    console.error(`compilation link error | ${key}`);
    return '#';
  }
};

export const getParam = (paramName) => {
  const { pathname } = window.location;

  const paramsRegex = new RegExp(`\\/${paramName}\\/(\\d+)`);
  const param = pathname.match(paramsRegex);

  if (!param) return null;

  return param[param.length - 1];
};
