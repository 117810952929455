import * as types from 'store/types/documents';

const initialState = {
  entries: [],
  pagination: {},
  isLoading: false,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case types.SET:
      return {
        ...state,
        ...action.payload,
      };
    case types.REMOVE_DOCUMENT:
      return {
        ...state,
        entries: state.entries.filter(item => item.id !== action.id),
        pagination: {
          ...state.pagination,
          count: state.pagination.count - 1
        }
      };
    case types.SET_LOADING_STATUS:
      return {
        ...state,
        isLoading: action.status,
      };
    case types.RESET:
      return {
        ...initialState,
      };
    default:
      return state;
  }
};
