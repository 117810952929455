import { all, put, call, takeLatest, takeEvery } from 'redux-saga/effects';
import { message } from 'antd';
import history from './../../history';
import * as api from 'store/sagas/api';
import * as types from 'store/types/superAdmin';
import * as actions from 'store/actions/superAdmin';
import links from 'config/links';

export function* checkIfSuperAdminCreated() {
  yield put(actions.setLoadingStatus(true));

  try {
    const { isSuperAdminCreated } = yield api.get('/checkSuperAdmin');

    yield put(actions.changeSuperAdminStatus(isSuperAdminCreated));
  } catch(error) {
    message.error('Failed to check super admin.');
  }

  yield put(actions.setLoadingStatus(false));
}

export function* createSuperAdmin({ credentials }) {
  yield put(actions.setLoadingStatus(true));

  try {
    const { success } = yield api.post('/createSuperAdmin', credentials);

    message.success('Super admin was created.');
    yield put(actions.changeSuperAdminStatus(success));
    yield call(history.push, links.login);
  } catch(error) {
    message.error('Something went wrong during super admin creation.');
    yield put(actions.changeSuperAdminStatus(false));
    yield put(actions.setError(error.message));
  }

  yield put(actions.setLoadingStatus(false));
}

export default function* () {
  yield all([
    yield takeLatest(types.CHECK_IF_SUPER_ADMIN_CREATED, checkIfSuperAdminCreated),
    yield takeEvery(types.CREATE_SUPER_ADMIN, createSuperAdmin),
  ]);
}
