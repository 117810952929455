import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { Table, Empty, Popconfirm, Button } from 'antd';
import { Link } from 'react-router-dom';
import moment from 'moment';
import { createLink } from 'core/utils/links';
import styles from './styles.module.scss';

class ProjectTable extends PureComponent {
  static propTypes = {
    isLoading: PropTypes.bool,
    projects: PropTypes.array,
    actions: PropTypes.shape({
      deleteProject: PropTypes.func,
    }),
    withActionButtons: PropTypes.bool,
    emptyTableDescription: PropTypes.object,
  };

  static defaultProps = {
    isLoading: false,
    projects: [],
    actions: {},
    withActionButtons: false,
    emptyTableDescription: <span>No data</span>,
  };

  renderColumns = () => {
    const { actions, withActionButtons } = this.props;
    const columns = [
      {
        title: '№',
        dataIndex: 'index',
        key: 'index',
        width: 80,
        render: idx => idx + 1
      },
      {
        title: 'Title',
        dataIndex: 'name',
        key: 'name',
      },
      {
        title: 'Annotation mode',
        dataIndex: 'annotation_mode',
        key: 'annotation_mode',
        align: 'center',
        render: value => value || '–',
        width: 220
      },
      {
        title: 'Created',
        dataIndex: 'created_at',
        key: 'created_at',
        align: 'center',
        render: date => moment(date).format('DD-MM-YYYY'),
        width: 140
      },
      {
        title: 'Updated',
        dataIndex: 'updated_at',
        key: 'updated_at',
        align: 'center',
        render: date => moment(date).format('DD-MM-YYYY'),
        width: 140
      },
      ...(withActionButtons ? [{
        title: 'Actions',
        key: 'actions',
        align: 'center',
        render: (el) => {
          return (
            <div className={styles.buttons}>
              <Link to={createLink('project', { projectId: el.id })}>
                <Button shape="circle" size="small">
                  <i className="icon-arrow-right" />
                </Button>
              </Link>

              <Popconfirm
                placement="leftTop"
                title={`Are you sure to delete "${el.name}" project?`}
                onConfirm={() => actions.deleteProject(el.id, el.index)}
                okText="Yes"
                cancelText="No"
              >
                <Button shape="circle" size="small">
                  <i className="icon-delete" />
                </Button>
              </Popconfirm>
            </div>
          )
        },
        width: 120
      }] : [])
    ];

    return columns;
  };

  render() {
    const {
      projects,
      emptyTableDescription,
    } = this.props;

    return (
      <Table
        bordered
        columns={this.renderColumns()}
        dataSource={projects.map((item, i) => ({
          ...item,
          key: item.id,
          index: i
        }))}
        locale={{
          emptyText: <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} description={emptyTableDescription} />
        }}
      />
    );
  }
}

export default ProjectTable;