import { combineReducers } from 'redux';
import auth from './auth';
import user from './user';
import documents from './documents';
import questions from './questions';
import answers from './answers';
import document from './document';
import activeQuestion from './activeQuestion';
import organization from './organization';
import projects from './projects';
import organizations from './organizations';
import users from './users';
import superAdmin from './superAdmin';
import imports from './imports';

export default combineReducers({
  auth,
  user,
  document,
  activeQuestion,
  documents,
  questions,
  answers,
  organizations,
  users,
  projects,
  organization,
  imports,
  superAdmin,
});
