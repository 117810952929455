import * as types from 'store/types/document';

const initialState = {
  document: {},
  pagination: {},
  questions: [],
  answers: [],
  isQuestionLoading: false,
  showAllLabels: false,
  isLoading: false
};

export default (state = initialState, action) => {
  switch (action.type) {
    case types.SET:
      return {
        ...state,
        ...action.payload
      };
    case types.SET_ANSWERS:
      return {
        ...state,
        answers: action.answers
      };
    case types.UPDATE_FIELD:
      return {
        ...state,
        document: {
          ...state.document,
          [action.field]: action.value,
        }
      };
    case types.ADD_QUESTION:
      return {
        ...state,
        questions: [
          ...state.questions,
          action.question
        ]
      };
    case types.TOGGLE_SHOW_ALL_LABELS_STATUS:
      return {
        ...state,
        showAllLabels: !state.showAllLabels
      };
    case types.CHANGE_QUESTION:
      return {
        ...state,
        questions: state.questions.map(item => {
          if (item.id === action.id) {
            return { ...item, ...action.question };
          }
          return item;
        })
      };
    case types.REMOVE_QUESTION:
      return {
        ...state,
        questions: state.questions.filter(item => item.id !== action.id)
      };
    case types.CHANGE_ANSWER: {
      const prevAnswerIndex = state.answers
        .findIndex(i => (i.question_id === action.questionId));

      // if answer not exist yet
      if (prevAnswerIndex === -1) {
        return {
          ...state,
          answers: [...state.answers, action.data]
        };
      }

      // otherwise, update an existing answer
      return {
        ...state,
        answers: state.answers.map(item => {
          if (item.question_id === action.questionId) {
            return {
              ...item,
              ...action.data
            };
          }

          return item;
        }),
      };
    }
    case types.REMOVE_ANSWER:
      return {
        ...state,
        answers: state.answers
          .filter(item => item.id !== action.id),
      };
    case types.SET_QUESTION_LOADING_STATUS:
      return {
        ...state,
        isQuestionLoading: action.status,
      };
    case types.SET_LOADING_STATUS:
      return {
        ...state,
        isLoading: action.status,
      };
    case types.RESET:
      return {
        ...initialState,
      };
    default:
      return state;
  }
};
