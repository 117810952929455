import { prefix } from 'core/utils/string';

const importPrefix = prefix('imports/');

export const UPLOAD_DOCUMENTS = importPrefix('UPLOAD_DOCUMENTS');
export const SET_DOCUMENTS_UPLOAD_PROGRESS = importPrefix('SET_DOCUMENTS_UPLOAD_PROGRESS');
export const SET_DOCUMENTS_UPLOADING_STATUS = importPrefix('SET_DOCUMENTS_UPLOADING_STATUS');
export const RESET_DOCUMENTS = importPrefix('RESET_DOCUMENTS');

export const UPLOAD_DOCUMENTS_CSV = importPrefix('UPLOAD_DOCUMENTS_CSV');
export const SET_DOCUMENTS_CSV_UPLOADING_STATUS = importPrefix('SET_DOCUMENTS_CSV_UPLOADING_STATUS');

export const SET_DOCUMENTS_CSV_ERRORS = importPrefix('SET_DOCUMENTS_CSV_ERRORS');
export const RESET_DOCUMENTS_CSV = importPrefix('RESET_DOCUMENTS_CSV');

export const UPLOAD_QUESTIONS_CSV = importPrefix('UPLOAD_QUESTIONS_CSV');
export const SET_QUESTIONS_CSV_UPLOADING_STATUS = importPrefix('SET_QUESTIONS_CSV_UPLOADING_STATUS');

export const SET_QUESTIONS_CSV_ERRORS = importPrefix('SET_QUESTIONS_CSV_ERRORS');
export const RESET_QUESTIONS_CSV = importPrefix('RESET_QUESTIONS_CSV');
