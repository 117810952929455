import React, { Component } from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import { Form, Input, Button } from 'antd';
import styles from './styles.module.scss';

export class LoginForm extends Component {

  static propTypes = {
    form: PropTypes.object,
    error: PropTypes.string,
    onSubmit: PropTypes.func,
    onClearError: PropTypes.func,
  }

  handleSubmit = (e) => {
    e.preventDefault();
    this.props.form.validateFields((err, values) => {
      if (!err) {
        this.props.onSubmit(values.email, values.password);
      }
    });
  }

  render() {
    const { getFieldDecorator } = this.props.form;
    const { error } = this.props;

    return (
      <Form onSubmit={this.handleSubmit} className={styles.form}>
        <Form.Item>
          {getFieldDecorator('email', {
            rules: [{ required: true, message: 'Please input your email!' }],
          })(
            <Input suffix={<i className={cn('icon-mail', styles.inputIcon)} />} placeholder="Email" type="email" autoFocus />
          )}
        </Form.Item>

        <Form.Item>
          {getFieldDecorator('password', {
            rules: [{ required: true, message: 'Please input your password!' }],
          })(
            <Input suffix={<i className={cn('icon-lock', styles.inputIcon)} />} type="password" placeholder="Password" />
          )}
        </Form.Item>


        <Button block type="primary" onClick={this.handleSubmit}>
          Log in
        </Button>

        { error && <div className={styles.error}>{error}</div>}
      </Form>
    );
  }
}

export const WrappedLoginForm = Form.create({
  onFieldsChange: (props, fields) => {
    if (props.error) {
      props.onClearError();
    }
  }
})(LoginForm);
