import * as types from 'store/types/superAdmin';

export const setError = (error) => ({
  type: types.SET_ERROR,
  error
});

export const resetError = () => ({
  type: types.RESET_ERROR
});

export const setLoadingStatus = (status) => ({
  type: types.SET_LOADING_STATUS,
  status
});

export const checkIfSuperAdminCreated = () => ({
  type: types.CHECK_IF_SUPER_ADMIN_CREATED,
});

export const changeSuperAdminStatus = (status) => ({
  type: types.CHANGE_SUPER_ADMIN_STATUS,
  status
});

export const createSuperAdmin = (credentials) => ({
  type: types.CREATE_SUPER_ADMIN,
  credentials
});