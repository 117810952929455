import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import * as actions from 'store/actions/organizations';
import Loader from 'components/common/Loader';
import EditOrganization from 'components/modals/EditOrganization';
import OrganizationsTable from './OrganizationsTable';

import styles from './styles.module.scss';

class Organizations extends PureComponent {
  state = {
    isVisibleModal: false,
    openedOrganization: null
  };

  static propTypes = {
    organizations: PropTypes.object,
    getOrganizations: PropTypes.func,
    editOrganization: PropTypes.func,
    deleteOrganization: PropTypes.func,
    resetError: PropTypes.func,
  };

  static defaultProps = {
    organizations: {},
    getOrganizations: () => {},
    editOrganization: () => {},
    deleteOrganization: () => {},
    resetError: () => {},
  };

  onToggleModal = (shouldOpen, openedOrganization = null) => {
    this.setState({
      isVisibleModal: shouldOpen,
      openedOrganization: openedOrganization
    });
  };

  onSubmitModal = (newFields) => {
    this.props.editOrganization(newFields, this.state.openedOrganization);
    this.onToggleModal(false);
  };

  render() {
    const {
      organizations: {
        entries: organizations,
        isLoading,
        error
      },
      deleteOrganization,
      resetError,
    } = this.props;

    const { isVisibleModal } = this.state;

    if (!organizations.length && isLoading) {
      return <Loader />;
    }

    return (
      <Loader loading={isLoading}>
        <div className={styles.wrapper}>
          <div className={styles.title}>
            <h1 className="h2">Super Admin - Organizations</h1>
            <p>This view shows you all registered organizations in the system. If you want to start labelling, plase do not use this account. Instead logout (top-right) and create a new regular user.</p>
          </div>

          <EditOrganization
            visible={isVisibleModal}
            title="Edit organization"
            onClose={() => this.onToggleModal(false)}
            onClearError={resetError}
            onSubmit={this.onSubmitModal}
            error={error}
          />

          <OrganizationsTable
            organizations={organizations}
            deleteOrganization={deleteOrganization}
            onOpenModal={this.onToggleModal}
          />
        </div>
      </Loader>
    );
  }

  componentDidMount() {
    this.props.getOrganizations();
  }
}

const mapStateToProps = ({ organizations }) => ({ organizations });

const mapDispatchToProps = (dispatch) => ({
  getOrganizations: () => dispatch(actions.get()),
  editOrganization: (newFields, id) => dispatch(actions.editOrganization(newFields, id)),
  deleteOrganization: (id, organizationIdx) => dispatch(actions.deleteOrganization(id, organizationIdx)),
  resetError: () => dispatch(actions.resetError()),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Organizations);
