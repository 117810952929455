import React, { PureComponent } from 'react';
import { Form, Input, Button, Icon, Alert } from 'antd';
import PropTypes from 'prop-types';

import styles from './styles.module.scss';

class CreateSuperAdminForm extends PureComponent {
  static propTypes = {
    form: PropTypes.object,
    error: PropTypes.string,
    onSubmit: PropTypes.func,
    onClearError: PropTypes.func,
    toPrevStep: PropTypes.func,
  };

  state = {
    confirmDirty: false,
    autoCompleteResult: [],
  };

  handleConfirmBlur = e => {
    const { value } = e.target;

    this.setState({ confirmDirty: this.state.confirmDirty || !!value });
  };

  compareToFirstPassword = (rule, value, callback) => {
    const { form } = this.props;

    if (value && value !== form.getFieldValue('password')) {
      callback('Two passwords that you enter is inconsistent!');
    } else {
      callback();
    }
  };

  validateToNextPassword = (rule, value, callback) => {
    const { form } = this.props;

    if (value && this.state.confirmDirty) {
      form.validateFields(['confirm'], { force: true });
    }

    callback();
  };

  handleSubmit = (e) => {
    e.preventDefault();

    this.props.form.validateFields((err, values) => {
      if (!err) {
        this.props.onSubmit(values);
      }
    });
  };

  render() {
    const {
      error,
      toPrevStep,
      form: {
        getFieldDecorator
      },
    } = this.props;

    return (
      <Form onSubmit={this.handleSubmit}>
        <Form.Item>
          {getFieldDecorator('login', {
            rules: [{ required: true, message: 'Please input login' }],
          })(<Input placeholder="Login" type="text" autoFocus />)}
        </Form.Item>

        <Form.Item hasFeedback>
          {getFieldDecorator('password', {
            rules: [
              { required: true, message: 'Please input password' },
              { validator: this.validateToNextPassword }
            ]
          })(<Input.Password placeholder="Password" type="password" />)}
        </Form.Item>

        <Form.Item hasFeedback>
          {getFieldDecorator('confirm_password', {
            rules: [
              { required: true, message: 'Please confirm password' },
              { validator: this.compareToFirstPassword },
            ],
          })(<Input.Password placeholder="Repeat password" onBlur={this.handleConfirmBlur} />)}
        </Form.Item>

        <Alert
          message="Please make sure you remember your password! There is no way to restore it!"
          type="warning"
          closable
        />

        <div className={styles.buttonsBlock}>
          <Button
            type="primary"
            onClick={toPrevStep}
          >
            <Icon type="arrow-left" />
          </Button>

          <Button
            type="primary"
            onClick={this.handleSubmit}
          >
            Create super admin
          </Button>
        </div>

        { error && <div className={styles.error}>{error}</div>}
      </Form>
    );
  }
}

export default Form.create({
  onFieldsChange: (props) => {
    if (props.error) {
      props.onClearError();
    }
  }
})(CreateSuperAdminForm);
