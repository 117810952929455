import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { Empty, Table } from 'antd';

export class UserTable extends PureComponent {
  static propTypes = {
    emptyTableDescription: PropTypes.object,
    users: PropTypes.array,
    additionalColumns: PropTypes.array,
    actions: PropTypes.func,
  };

  static defaultProps = {
    users: [],
    additionalColumns: [],
    actions: () => {},
    emptyTableDescription: <span>No data</span>,
  };

  renderColumns = () => {
    const {
      additionalColumns = [],
      actions
    } = this.props;

    const actionsColumn = {
      title: 'Actions',
      key: 'action',
      align: 'center',
      render: actions,
      width: 120
    };

    const columns = [
      {
        title: '№',
        dataIndex: 'index',
        key: 'index',
        width: 80,
        render: idx => idx + 1
      },
      {
        title: 'First name',
        dataIndex: 'first_name',
        key: 'first_name'
      },
      {
        title: 'Last name',
        dataIndex: 'last_name',
        key: 'last_name'
      },
      {
        title: 'Email',
        dataIndex: 'email',
        key: 'email'
      },
      ...additionalColumns,
    ];

    if (actions) {
      columns.push(actionsColumn);
    }

    return columns;
  };

  render() {
    const {
      users,
      emptyTableDescription
    } = this.props;

    return (
      <Table
        bordered
        columns={this.renderColumns()}
        dataSource={users.map((item, index) => ({
          ...item,
          key: item.id,
          index
        }))}
        pagination={{
          pageSize: 30,
          total: users.length,
        }}
        locale={{
          emptyText: <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} description={emptyTableDescription} />
        }}
      />
    );
  }
}

export default UserTable;