import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { Switch } from 'antd';
import cn from 'classnames';
import styles from './styles.module.scss';

class SwitchButton extends PureComponent {
  static propTypes = {
    checked: PropTypes.bool,
    text: PropTypes.string,
    className: PropTypes.string,
    fluid: PropTypes.bool,
    autoWidth: PropTypes.bool,
    disabled: PropTypes.bool,
    onChange: PropTypes.func,
  }

  static defaultProps = {
    checked: false,
    text: '',
    fluid: false,
    autoWidth: false,
    disabled: false,
    className: '',
    onChange: () => {}
  }

  render() {
    const { checked, text, fluid, autoWidth, disabled, className, onChange } = this.props;
    return (
      <div
        className={cn(
          styles.button,
          { [styles.fluid]: fluid },
          { [styles.autoWidth]: autoWidth },
          { [styles.disabled]: disabled },
          className
        )}
      >
        <span className={styles.buttonText}>{text}</span>
        <Switch
          size="small"
          disabled={disabled}
          checked={checked}
          onChange={onChange}
        />
      </div>
    );
  }
}

export default SwitchButton;
