import React from 'react';

import styles from './styles.module.scss';

const AuthWrapper = ({ children, title }) => (
  <div className={styles.wrapper}>
    <div className={styles.container}>
      <h1 className="h2">{title}</h1>
      {
        children
      }
    </div>
  </div>
);

export default AuthWrapper;