import * as types from 'store/types/answers';

const initialState = {
  entries: [],
  isLoading: false
};

export default (state = initialState, action) => {
  switch (action.type) {
    case types.SET:
      return {
        ...state,
        entries: action.payload
      };
    case types.REMOVE_ANSWER:
      return {
        ...state,
        entries: state.entries.filter(item => item.id !== action.id),
      };
    case types.SET_LOADING_STATUS:
      return {
        ...state,
        isLoading: action.status,
      };

    case types.RESET:
      return {
        ...initialState,
      };
    default:
      return state;
  }
};
