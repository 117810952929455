import * as types from 'store/types/projects';

export const get = (organizationId) => ({
  type: types.GET,
  organizationId
});

export const set = (payload) => ({
  type: types.SET,
  payload,
});

export const setActiveProject = (payload) => ({
  type: types.SET_ACTIVE_PROJECT,
  payload,
});

export const createProject = (payload) => ({
  type: types.CREATE_PROJECT,
  payload
});

export const addProjectItem = (payload) => ({
  type: types.ADD_PROJECT_ITEM,
  payload,
});

export const deleteProject = (projectId, projectIdx) => ({
  type: types.DELETE_PROJECT,
  projectId,
  projectIdx
});

export const removeProjectItem = (id) => ({
  type: types.REMOVE_PROJECT_ITEM,
  id
});

export const setLoadingStatus = (status) => ({
  type: types.SET_LOADING_STATUS,
  status
});

export const reset = () => ({
  type: types.RESET,
});

export const setError = (error) => ({
  type: types.SET_ERROR,
  error
});

export const resetError = () => ({
  type: types.RESET_ERROR,
});
