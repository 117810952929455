import { prefix } from 'core/utils/string';

const projectsPrefix = prefix('projects/');

export const GET = projectsPrefix('GET');
export const SET = projectsPrefix('SET');
export const SET_ACTIVE_PROJECT = projectsPrefix('SET_ACTIVE_PROJECT');
export const CREATE_PROJECT = projectsPrefix('CREATE_PROJECT');
export const ADD_PROJECT_ITEM = projectsPrefix('ADD_PROJECT_ITEM');
export const DELETE_PROJECT = projectsPrefix('DELETE_PROJECT');
export const REMOVE_PROJECT_ITEM = projectsPrefix('REMOVE_PROJECT_ITEM');

export const SET_LOADING_STATUS = projectsPrefix('SET_LOADING_STATUS');
export const RESET = projectsPrefix('RESET');
export const SET_ERROR = projectsPrefix('SET_ERROR');
export const RESET_ERROR = projectsPrefix('RESET_ERROR');