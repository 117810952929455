import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import { Link } from 'react-router-dom';
import styles from './styles.module.scss';

class LinkButton extends PureComponent {
  static propTypes = {
    text: PropTypes.string,
    to: PropTypes.oneOfType([
      PropTypes.object,
      PropTypes.string
    ]),
    icon: PropTypes.oneOfType([
      PropTypes.bool,
      PropTypes.string
    ]),
    theme: PropTypes.oneOf(['primary']),
    className: PropTypes.string,
    disabled: PropTypes.bool,
    replace: PropTypes.bool,
    onClick: PropTypes.func,
  }

  static defaultProps = {
    text: '',
    to: '#',
    icon: 'icon-right', // fontello icon name
    theme: 'primary',
    disabled: false,
    replace: false,
    onClick: () => {}
  }

  static themes = {
    PRIMARY: 'primary',
  }

  render() {
    const { text, icon, to, theme, disabled, replace, className, onClick } = this.props;
    return (
      <Link
        to={to}
        replace={replace}
        className={cn(
          styles.link,
          { [styles.disabled]: disabled },
          styles[theme],
          className
        )}
        onClick={onClick}
      >
        <span>{text}</span>
        { icon && <i className={cn(icon, styles.linkIcon)} /> }
      </Link>
    );
  }
}

export default LinkButton;
