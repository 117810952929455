import { colors } from 'core/constants/colors';

// Transforms hex to rgba
// Supports both short #fff and long #ffffff forms.
// Supports alpha channel (opacity).
// Does not care if hash specified or not, works in both cases.
export const hexToRGBA = (hex = '#000000', opacity = 1) => {
  const value = (hex = hex.replace('#', ''))
    .match(new RegExp('(.{' + hex.length / 3 + '})', 'g'))
    .map(function(l) { return parseInt(hex.length % 2 ? l + l : l, 16);})
    .concat(opacity||1).join(',');

  return `rgba(${value})`;
};

export const getRandomColor = () => colors[Math.floor(Math.random() * colors.length)];
