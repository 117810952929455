import * as types from 'store/types/activeQuestion';

const initialState = {
  id: null,
  index: null,
  data: {}
};

export default (state = initialState, action) => {
  switch (action.type) {
    case types.SET:
      return {
        ...state,
        id: action.data.id,
        index: action.index,
        data: action.data,
      };
    case types.RESET:
      return {
        ...initialState,
      };
    default:
      return state;
  }
};
