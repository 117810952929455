import * as types from 'store/types/organization';

const initialState = {
  entries: [],
  isLoading: false,
  error: '',
  newUser: null
};

export default (state = initialState, action) => {
  switch (action.type) {
    case types.SET_USERS:
      return {
        ...state,
        entries: action.entries
      };

    case types.SET_NEW_USER: {
      const newUser = action.user;
      const allUsers = state.entries;

      return {
        ...state,
        newUser: newUser,
        entries: [
          newUser,
          ...allUsers
        ]
      };
    }

    case types.REMOVE_NEW_USER:
      return {
        ...state,
        newUser: null
      };

    case types.SET_LOADING_STATUS:
      return {
        ...state,
        isLoading: action.status
      };

    case types.SET_ERROR:
      return {
        ...state,
        error: action.error
      };

    case types.RESET_ERROR:
      return {
        ...state,
        error: ''
      };

    case types.RESET:
    default:
      return state;
  }
};
