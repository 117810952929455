import React, { PureComponent, Fragment } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Link } from 'react-router-dom';
import { Table, Tag, Button, Empty, Popconfirm } from 'antd';
import moment from 'moment';
import * as actions from 'store/actions/documents';
import { documentStates } from 'core/constants/types';
import { Loader } from 'components/common';
import { createLink } from 'core/utils/links';
import styles from './styles.module.scss';

const emptyTableDescription = (
  <Fragment>
    <span className="emptyTableText">Every labelling project starts with documents to label.</span>
    <span>Please start to upload them here.</span>
  </Fragment>
);

export class Documents extends PureComponent {

  state = {
    loaded: 0,
    prevLoadedValue: 0,
  };

  static propTypes = {
    match: PropTypes.object,
    documents: PropTypes.object,
    actions: PropTypes.object,
  }

  static defaultProps = {
    documents: {},
    actions: {}
  }

  handleDocumentDelete = (id) => () => {
    this.props.actions.deleteDocument(id);
  }

  renderColumns = () => {
    return [
      {
        title: 'Id',
        dataIndex: 'key',
        key: 'key',
        width: 80,
      },
      {
        title: 'Text',
        dataIndex: 'text',
        key: 'text',
        className: styles.textCol,
        width: 700
      },
      {
        title: 'Status',
        dataIndex: 'status',
        key: 'status',
        align: 'center',
        render: status => {
          let color = status === documentStates.NEW ? 'geekblue' : 'green';
          if (status === documentStates.INPROGRESS) {
            color = 'volcano';
          }

          return (
            status ? (
              <Tag color={color}>
                {status.toUpperCase()}
              </Tag>
            ) : '-'
          );
        }
      },
      {
        title: 'Labeler no.',
        dataIndex: 'user_id',
        key: 'user_id',
        align: 'center',
        render: (user_id) => user_id || '-'
      },
      {
        title: 'Created',
        dataIndex: 'createdAt',
        key: 'createdAt',
        align: 'center',
        render: date => moment(date).format('DD-MM-YYYY')
      },
      {
        title: 'Updated',
        dataIndex: 'updatedAt',
        key: 'updatedAt',
        align: 'center',
        render: date => moment(date).format('DD-MM-YYYY')
      },
      {
        title: 'Actions',
        key: 'action',
        align: 'center',
        render: el => {
          return (
            <div className={styles.buttons}>
              <Link to={createLink('document', { projectId: this.props.match.params.projectId, documentId: el.id })}>
                <Button shape="circle" size="small">
                  <i className="icon-arrow-right" />
                </Button>
              </Link>

              <Popconfirm
                placement="leftTop"
                title="Are you sure to delete this document?"
                onConfirm={this.handleDocumentDelete(el.key)}
                okText="Yes"
                cancelText="No"
              >
                <Button shape="circle" size="small">
                  <i className="icon-delete" />
                </Button>
              </Popconfirm>
            </div>
          );
        },
        width: 120
      },
    ];
  }

  componentDidMount() {
    const {
      match,
      actions
    } = this.props;

    actions.getAll(match.params.projectId);
  }

  componentDidUpdate({ match: { params: prevParams } }) {
    const {
      match: { params: currentParams },
      actions
    } = this.props;

    if (currentParams.projectId !== prevParams.projectId) {
      actions.getAll(currentParams.projectId);
    }
  }

  render() {
    const { entries, pagination, isLoading } = this.props.documents;

    if (!entries.length && isLoading) {
      return <Loader />;
    }

    return (
      <Loader loading={isLoading}>
        <div className={styles.wrapper}>
          <div className={styles.title}>
            <h1 className="h2">Documents</h1>
          </div>

          <Table
            bordered
            columns={this.renderColumns()}
            dataSource={entries.map((item, i) => ({
              ...item,
              key: item.id,
              index: i
            }))}
            pagination={{
              pageSize: 30,
              total: pagination.count
            }}
            locale={{
              emptyText: <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} description={emptyTableDescription} />
            }}
          />
        </div>
      </Loader>
    );
  }
}

export default connect(
  state => ({
    documents: state.documents,
  }),
  dispatch => ({
    actions: bindActionCreators(actions, dispatch),
  })
)(Documents);
