import { all, put, call, takeLatest } from 'redux-saga/effects';
import { message } from 'antd';
import { setToken, removeToken } from 'store/sagas/helpers/localStorage';
import history from './../../history';
import * as api from 'store/sagas/api';
import * as types from 'store/types/auth';
import * as actions from 'store/actions/auth';
import links from 'config/links';

export function* authorize({ email, password }) {
  yield put(actions.setLoadingStatus(true));
  try {
    const { token } = yield api.post('/user/login', { email, password });
    yield setToken(token);
    yield put(actions.setAuthData(token));

  } catch(error) {
    yield put(actions.setError(error.message));
    yield removeToken();
  }
  yield put(actions.setLoadingStatus(false));
}

export function* logout() {
  yield put(actions.setLoadingStatus(true));
  try {
    yield removeToken();
  } catch(error) {
    message.error(error.message);
  }
  yield put(actions.setLoadingStatus(false));
}

export function* registration({ registrationData }) {
  yield put(actions.setLoadingStatus(true));

  try {
    const { success } = yield api.post('/user/registration', { registrationData });

    if (success) {
      message.success('Registration successful, now you can login.');

      yield call(history.push, links.login);
    } else {
      message.error('Something went wrong during registration.');
    }

  } catch(error) {
    yield put(actions.setError(error.message));
  }

  yield put(actions.setLoadingStatus(false));
}

export default function* () {
  yield all([
    yield takeLatest(types.LOGIN, authorize),
    yield takeLatest(types.DO_REGISTRATION, registration),
    yield takeLatest(types.LOGOUT, logout),
  ]);
}
