import React, { PureComponent, Fragment } from 'react';
import { Redirect } from 'react-router-dom';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Button, Icon } from 'antd';
import * as actions from 'store/actions/organization';
import * as usersActions from 'store/actions/users';
import { Loader } from 'components/common';
import CreateUserModal from 'components/modals/CreateUser';
import ResetPassword from 'components/modals/ResetPassword';
import UserTable from 'components/UserTable';
import roles from 'core/constants/roles';
import links from 'config/links';
import styles from './styles.module.scss';

export class UserManagement extends PureComponent {
  state = {
    isResetPasswordModalVisible: false,
    isCreateUserModalVisible: false,
    selectedUser: null,
  };
  
  static propTypes = {
    isLoading: PropTypes.bool,
    users: PropTypes.array,
    organizationActions: PropTypes.object,
    error: PropTypes.string,
    newUserInOrganization: PropTypes.object
  };

  static defaultProps = {
    isLoading: false,
    users: [],
    organizationActions: {},
    error: '',
    newUserInOrganization: null
  };

  onToggleModal = (shouldOpen, modalName, selectedUser = null) => {
    const {
      newUserInOrganization,
      organizationActions
    } = this.props;

    this.setState({
      [modalName]: shouldOpen,
      selectedUser
    });

    if (newUserInOrganization && modalName === 'isCreateUserModalVisible') {
      organizationActions.removeNewUser();
    }
  };

  render() {
    const {
      users,
      isLoading,
      error,
      organizationActions,
      usersActions,
      newUserInOrganization,
      user
    } = this.props;

    const {
      isResetPasswordModalVisible,
      isCreateUserModalVisible,
      selectedUser
    } = this.state;

    if (user.role !== roles.ADMIN) {
      return (<Redirect to={links.projects} />);
    }

    return (
      <Loader loading={isLoading}>
        <div className={styles.wrapper}>
          <div className={styles.title}>
            <h1 className="h2">User management</h1>

            <Button
              type="primary"
              onClick={() => this.onToggleModal(true, 'isCreateUserModalVisible')}
            >
              Create new user
            </Button>
          </div>

          <UserTable
            users={users}
            emptyTableDescription={
              <Fragment>
                <span className="emptyTableText">You don't have any users.</span>
                <span>Use "Create new user" button on top right, to add a new user to organization.</span>
              </Fragment>
            }
            actions={({ id }) => (
              <div>
                <Button shape="circle" size="small" onClick={() => this.onToggleModal(true, 'isResetPasswordModalVisible', id)}>
                  <Icon type="setting" />
                </Button>
              </div>
            )}
          />

          <ResetPassword
            visible={isResetPasswordModalVisible}
            title="Reset password"
            onClose={() => this.onToggleModal(false, 'isResetPasswordModalVisible')}
            onSubmit={({ password }) => usersActions.resetPassword(password, selectedUser)}
          />

          <CreateUserModal
            title="Create user"
            visible={isCreateUserModalVisible}
            onClose={() => this.onToggleModal(false, 'isCreateUserModalVisible')}
            onClearError={organizationActions.resetError}
            onSubmit={organizationActions.addUserToOrganization}
            newUserInOrganization={newUserInOrganization}
            error={error}
          />
        </div>
      </Loader>
    );
  }

  componentDidMount() {
    if (this.props.user.role === roles.ADMIN) {
      this.props.organizationActions.getUsers();
    }
  }

  componentWillUnmount() {
    this.props.organizationActions.reset();
  }
}

const mapStateToProps = ({ organization, user }) => ({
  isLoading: organization.isLoading,
  users: organization.entries,
  error: organization.error,
  newUserInOrganization: organization.newUser,
  user
});

const mapDispatchToProps = (dispatch) => ({
  organizationActions: bindActionCreators(actions, dispatch),
  usersActions: bindActionCreators(usersActions, dispatch)
});

export default connect(mapStateToProps, mapDispatchToProps)(UserManagement);
