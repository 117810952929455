import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { Form, Input, Select, Button } from 'antd';
import { questionCategories, answerCategories, answerCategoriesText } from 'core/constants/types';

class AddQuestionForm extends PureComponent {

  static propTypes = {
    form: PropTypes.object,
    question: PropTypes.shape({
      text: PropTypes.string,
      category: PropTypes.string
    }),
    uniqueCategory: PropTypes.bool,
    withAnswerCategorySelect: PropTypes.bool,
    onSubmit: PropTypes.func,
  }

  static defaultProps = {
    question: {
      text: '',
      category: undefined
    },
    uniqueCategory: false,
    withAnswerCategorySelect: false,
    onSubmit: () => {}
  }

  handleKeyPress = (e) => {
    const { keyCode, target } = e;

    // if Enter button was pressed
    if (keyCode === 13 && target.tagName !== 'TEXTAREA') {
      this.handleSubmit();
    }
  }

  handleSubmit = (e) => {
    if (e) {
      e.preventDefault();
    }

    this.props.form.validateFields((err, values) => {
      if (!err) {
        this.props.onSubmit({ text: values.text, category: values.category }, { answer_category: values.answer_category});
      }
    });
  }

  componentDidMount() {
    window.addEventListener('keydown', this.handleKeyPress);
  }

  render() {
    const { getFieldDecorator } = this.props.form;

    return (
      <Form onSubmit={this.handleSubmit} hideRequiredMark colon={false}>
        <Form.Item label="Question text">
          {getFieldDecorator('text', {
            rules: [{ required: true, message: 'Please enter a question!' }],
            initialValue: this.props.question.text
          })(
            <Input.TextArea placeholder="Question text" rows={4} autoFocus style={{ resize: 'none' }} />
          )}
        </Form.Item>
        <Form.Item label="Question category">
          {getFieldDecorator('category', {
            rules: [{ required: true, message: 'Please select a category!' }],
            initialValue: !this.props.uniqueCategory ? this.props.question.category : questionCategories.U
          })(
            <Select
              placeholder="Select a category"
              disabled={this.props.uniqueCategory}
            >
              {
                Object.values(questionCategories).map((category, i) => (
                  <Select.Option value={category} key={i} disabled={category === questionCategories.U}>
                    {category}
                  </Select.Option>
                ))
              }
            </Select>
          )}
        </Form.Item>
        {
          this.props.withAnswerCategorySelect && (
            <Form.Item label="Answer category">
              {getFieldDecorator('answer_category', {
                rules: [{ required: true, message: 'Please select a category!' }],
                initialValue: answerCategories.SHORT
              })(
                <Select placeholder="Select a category">
                  <Select.Option value={answerCategories.SHORT}>
                    {answerCategoriesText[answerCategories.SHORT]}
                  </Select.Option>
                  <Select.Option value={answerCategories.LONG}>
                    {answerCategoriesText[answerCategories.LONG]}
                  </Select.Option>
                  <Select.Option value={answerCategories.YES}>
                    {answerCategoriesText[answerCategories.YES]}
                  </Select.Option>
                  <Select.Option value={answerCategories.NO}>
                    {answerCategoriesText[answerCategories.NO]}
                  </Select.Option>
                  <Select.Option value={answerCategories.OTHER}>
                    {answerCategoriesText[answerCategories.OTHER]}
                  </Select.Option>
                </Select>
              )}
            </Form.Item>
          )
        }

        <Button block type="primary" htmlType="submit">Submit</Button>
      </Form>
    );
  }

  componentWillUnmount() {
    window.removeEventListener('keydown', this.handleKeyPress);
  }
}

export default Form.create({
  name: 'addQuestion'
})(AddQuestionForm);
