import * as types from 'store/types/organizations';

const initialState = {
  entries: [],
  activeOrganization: {},
  isLoading: false,
  error: '',
};

export default (state = initialState, action) => {
  switch (action.type) {
    case types.SET:
      return {
        ...state,
        entries: action.entries
      };

    case types.UPDATE_ORGANIZATION: {
      const {
        organizationToUpdate,
        newFields
      } = action;
      const oldOrganizationsList = state.entries;
      const updatedOrganization = {
        ...oldOrganizationsList[organizationToUpdate.index],
        ...newFields
      };
      const newOrganizationsList = [
        ...oldOrganizationsList.slice(0, organizationToUpdate.index),
        updatedOrganization,
        ...oldOrganizationsList.slice(organizationToUpdate.index + 1),
      ];

      return {
        ...state,
        entries: newOrganizationsList
      };
    }

    case types.SET_ORGANIZATION:
      return {
        ...state,
        activeOrganization: action.organization
      };

    case types.DELETE_FROM_STATE: {
      const idxOfElementToDelete = action.organizationIdx;
      const oldOrganizationsList = state.entries;
      const newOrganizationsList = [
        ...oldOrganizationsList.slice(0, idxOfElementToDelete),
        ...oldOrganizationsList.slice(idxOfElementToDelete + 1),
      ];

      return {
        ...state,
        entries: newOrganizationsList
      };
    }

    case types.SET_LOADING_STATUS:
      return {
        ...state,
        isLoading: action.status
      };

    case types.SET_ERROR:
      return {
        ...state,
        error: action.error
      };

    case types.RESET_ERROR:
      return {
        ...state,
        error: ''
      };

    case types.RESET:
    default:
      return state;
  }
};
