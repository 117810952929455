import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { Redirect } from 'react-router';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { bindActionCreators } from 'redux';

import AuthWrapper from '../AuthWrapper';
import RegistrationForm from './RegistrationForm';
import PrivacyPopup from 'components/modals/PrivacyPolicy';
import links from 'config/links';
import * as authActions from 'store/actions/auth';
import styles from './styles.module.scss';

class Registration extends PureComponent {
  static propTypes = {
    token: PropTypes.string,
    error: PropTypes.string,
    authActions: PropTypes.object
  };

  state = {
    isVisibleModal: false,
    selectedModal: '',
  };

  onToggleModal = (evt, selectedModal = '') => {
    evt.preventDefault();

    this.setState(({ isVisibleModal }) => ({
      isVisibleModal: !isVisibleModal,
      selectedModal
    }));
  };

  render() {
    const {
      token,
      error,
      authActions
    } = this.props;

    const {
      isVisibleModal,
      selectedModal,
    } = this.state;

    if (token) {
      return <Redirect to={links.projects} />;
    }

    return(
      <AuthWrapper title="Haystack Annotation Tool">
        <RegistrationForm
          error={error}
          onClearError={authActions.resetError}
          onSubmit={authActions.doRegistration}
          onOpenModal={this.onToggleModal}
        />

        <p className={styles.linkToRegistration}>
          Already have account? Please <Link to={links.login}>sign in</Link>.
        </p>

        <PrivacyPopup
          visible={isVisibleModal}
          privacyType={selectedModal}
          onClose={this.onToggleModal}
        />
      </AuthWrapper>
    );
  }

  componentWillUnmount () {
    this.props.authActions.resetError();
  }
}

const mapDispatchToProps = (dispatch) => ({
  authActions: bindActionCreators(authActions, dispatch)
});

const mapStateToProps = (state) => ({
  token: state.auth.token,
  error: state.auth.error
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Registration);
