import { prefix } from 'core/utils/string';

const superAdminPrefix = prefix('superAdmin/');

export const SET_ERROR = superAdminPrefix('SET_ERROR');
export const RESET_ERROR = superAdminPrefix('RESET_ERROR');
export const CHECK_IF_SUPER_ADMIN_CREATED = superAdminPrefix('CHECK_IF_SUPER_ADMIN_CREATED');
export const CHANGE_SUPER_ADMIN_STATUS = superAdminPrefix('CHANGE_SUPER_ADMIN_STATUS');
export const CREATE_SUPER_ADMIN = superAdminPrefix('CREATE_SUPER_ADMIN');

export const SET_LOADING_STATUS = superAdminPrefix('SET_LOADING_STATUS');
