import React, { PureComponent } from 'react';
import { Form, Input, Button } from 'antd';
import PropTypes from 'prop-types';
import styles from './styles.module.scss';

class CreateUserForm extends PureComponent {
  static propTypes = {
    form: PropTypes.object,
    error: PropTypes.string,
    onSubmit: PropTypes.func,
    onClearError: PropTypes.func,
  };

  handleSubmit = (e) => {
    e.preventDefault();

    this.props.form.validateFields((err, values) => {
      if (!err) {
        this.props.onSubmit(values);
      }
    });
  };

  render() {
    const {
      error,
      form: {
        getFieldDecorator
      },
    } = this.props;

    return (
      <Form onSubmit={this.handleSubmit} hideRequiredMark colon={false}>
        <Form.Item label="First name">
          {getFieldDecorator('first_name', {
            rules: [{ required: true, message: 'Please input first name!' }],
          })(<Input placeholder="First name" type="text" autoFocus />)}
        </Form.Item>

        <Form.Item label="Last name">
          {getFieldDecorator('last_name', {
            rules: [{ required: true, message: 'Please input last name!' }],
          })(<Input placeholder="Last name" type="text" />)}
        </Form.Item>

        <Form.Item label="Email">
          {getFieldDecorator('email', {
            rules: [
              { type: 'email', message: 'Email is not valid!' },
              { required: true, message: 'Please input email!' },
            ],
          })(<Input placeholder="Email" type="email" />)}
        </Form.Item>

        <Button
          block
          type="primary"
          onClick={this.handleSubmit}
        >
          Create user
        </Button>

        { error && <div className={styles.error}>{error}</div> }
      </Form>
    );
  }
}

export default Form.create({
  onFieldsChange: (props) => {
    if (props.error) {
      props.onClearError();
    }
  }
})(CreateUserForm);