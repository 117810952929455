import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { Button, Modal } from 'antd';

import privacyData from './privacy';

class PrivacyPolicy extends PureComponent {
  static propTypes = {
    onClose: PropTypes.func,
    visible: PropTypes.bool,
    privacyType: PropTypes.string,
  };

  static defaultProps = {
    onClose: () => {},
    visible: false,
    privacyType: 'Terms and conditions',
  };

  privacyDataContainer = React.createRef();

  render() {
    const {
      onClose,
      visible,
      privacyType,
    } = this.props;

    return (
      <Modal
        visible={visible}
        title={privacyType}
        width={650}
        footer={false}
        onCancel={onClose}
        forceRender
      >
        <div ref={this.privacyDataContainer} />

        <Button
          block
          type="primary"
          onClick={onClose}
        >
          OK
        </Button>
      </Modal>
    );
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    const {
      privacyType,
      visible,
    } = this.props;

    if (visible && privacyType !== prevProps.privacyType) {
      this.privacyDataContainer.current.innerHTML = privacyData[privacyType];
    }
  }
}

export default PrivacyPolicy;
