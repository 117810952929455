import * as types from 'store/types/auth';
import { getToken } from 'store/sagas/helpers/localStorage';

const initialState = {
  token: getToken() || null,
  error: '',
  isLoading: false,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case types.SET_AUTH_DATA:
      return {
        ...state,
        token: action.token,
        error: ''
      };
    case types.SET_ERROR:
      return {
        ...state,
        error: action.error
      };
    case types.RESET_ERROR:
      return {
        ...state,
        error: ''
      };
    case types.LOGOUT:
      return {
        ...initialState,
        token: null
      };
    case types.SET_LOADING_STATUS:
      return {
        ...state,
        isLoading: action.status
      };
    default:
      return state;
  }
};
