import { prefix } from 'core/utils/string';

const answersPrefix = prefix('answers/');

export const GET = answersPrefix('GET');
export const SET = answersPrefix('SET');

export const DELETE_ANSWER = answersPrefix('DELETE_ANSWER'); // for saga action
export const REMOVE_ANSWER = answersPrefix('REMOVE_ANSWER');
export const DOWNLOAD_DATA_IN_SQUAD_FORMAT = answersPrefix('DOWNLOAD_DATA_IN_SQUAD_FORMAT');

export const SET_LOADING_STATUS = answersPrefix('SET_LOADING_STATUS');
export const RESET = answersPrefix('RESET');
