import { all, put, takeLatest, takeEvery } from 'redux-saga/effects';
import { message } from 'antd';
import * as api from 'store/sagas/api';
import * as types from 'store/types/organizations';
import * as actions from 'store/actions/organizations';

export function* getOrganizations() {
  yield put(actions.setLoadingStatus(true));

  try {
    const organizations = yield api.get('/superadmin/organizations');

    yield put(actions.set(organizations));
  } catch(error) {
    yield put(actions.setError(error.message));
  }

  yield put(actions.setLoadingStatus(false));
}

export function* deleteOrganization({ id, organizationIdx }) {
  yield put(actions.setLoadingStatus(true));

  try {
    const { deletedOrganization } = yield api.del(`/superadmin/organizations/${id}`);

    if (deletedOrganization) {
      yield put(actions.deleteFromState(organizationIdx));
      message.success('Organization has been deleted.');
    }
  } catch(error) {
    yield put(actions.setError(error.message));
    message.error(error.message);
  }

  yield put(actions.setLoadingStatus(false));
}

export function* getOrganization({ organizationId }) {
  yield put(actions.setLoadingStatus(true));

  try {
    const { organization } = yield api.get(`/superadmin/organizations/${organizationId}`);

    yield put(actions.setOrganization(organization));
  } catch(error) {
    yield put(actions.setError(error.message));
  }

  yield put(actions.setLoadingStatus(false));
}

export function* editOrganization({ newFields, organizationToUpdate }) {
  yield put(actions.setLoadingStatus(true));

  try {
    const { editedOrganization } = yield api.put(`/superadmin/organizations/${organizationToUpdate.id}`, null, newFields);

    if (editedOrganization) {
      yield put(actions.updateOrganization(newFields, organizationToUpdate));
      message.success('Organization has been renamed.');
    }
  } catch(error) {
    yield put(actions.setError(error.message));
    message.success(error.message);
  }

  yield put(actions.setLoadingStatus(false));
}

export default function* () {
  yield all([
    yield takeLatest(types.GET, getOrganizations),
    yield takeLatest(types.GET_ORGANIZATION, getOrganization),
    yield takeEvery(types.DELETE, deleteOrganization),
    yield takeEvery(types.EDIT, editOrganization),
  ]);
}