import { all, put, takeLatest } from 'redux-saga/effects';
import { message } from 'antd';
import * as api from 'store/sagas/api';
import * as types from 'store/types/user';
import * as actions from 'store/actions/user';


export function* getInfo() {
  yield put(actions.setLoadingStatus(true));
  try {
    const data = yield api.get('/user/info');
    yield put(actions.setInfo(data.user));

  } catch (error) {
    message.error(error.message);
  }
  yield put(actions.setLoadingStatus(false));
}

export default function* () {
  yield all([
    yield takeLatest(types.GET_INFO, getInfo),
  ]);
}
