import * as types from 'store/types/users';

const initialState = {
  entries: [],
  isLoading: false,
  error: '',
};

export default (state = initialState, action) => {
  switch (action.type) {
    case types.SET_USERS:
      return {
        ...state,
        entries: action.entries
      };

    case types.SET_LOADING_STATUS:
      return {
        ...state,
        isLoading: action.status
      };

    case types.RESET:
    default:
      return state;
  }
};
