import * as types from 'store/types/activeQuestion';

export const set = (data, index) => ({
  type: types.SET,
  data,
  index
});

export const reset = () => ({
  type: types.RESET,
});
