import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { Modal } from 'antd';
import Form from './Form';
import styles from './styles.module.scss';

class AddQuestionModal extends PureComponent {

  static propTypes = {
    title: PropTypes.string,
    question: PropTypes.object,
    visible: PropTypes.bool,
    isDocumentQuestion: PropTypes.bool,
    withAnswerCategorySelect: PropTypes.bool,
    onClose: PropTypes.func,
    onSubmit: PropTypes.func,
  }

  static defaultProps = {
    title: '',
    question: { text: '', category: undefined },
    visible: true,
    isDocumentQuestion: false,
    withAnswerCategorySelect: false,
    onClose: () => {},
    onSubmit: () => {},
  }

  handleSubmit = (questionValues, answerValues) => {    
    this.props.onSubmit(questionValues, answerValues);
    this.props.onClose();
  }

  render() {
    const { visible, title, question, isDocumentQuestion, withAnswerCategorySelect, onClose } = this.props;
    return (
      <Modal
        wrapClassName={styles.modal}
        visible={visible}
        title={title ? title : `${question ? 'Edit' : 'Add' } Question`}
        width={400}
        centered
        footer={false}
        onCancel={onClose}
      >

        <Form
          question={question}
          uniqueCategory={isDocumentQuestion}
          withAnswerCategorySelect={withAnswerCategorySelect}
          onSubmit={this.handleSubmit}
        />
      </Modal>
    );
  }
}

export default AddQuestionModal;
