import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import PropTypes from 'prop-types';
import { Tabs } from 'antd';
import { Loader } from 'components/common';
import AnswersTab from './AnswersTab';
import * as actions from 'store/actions/answers';
import styles from './styles.module.scss';

const { TabPane } = Tabs;

class Answers extends PureComponent {
  static propTypes = {
    answers: PropTypes.object,
    user: PropTypes.object,
    isMyLabels: PropTypes.bool,
    match: PropTypes.object,
    actions: PropTypes.object,
  };

  static defaultProps = {
    answers: {},
    user: {},
    isMyLabels: false,
    match: {},
    actions: {},
  };

  handleAnswerDelete = (id) => () => {
    this.props.actions.deleteAnswer(id);
  };

  render() {
    const {
      answers: {
        entries,
        isLoading
      },
      user,
      match,
      actions: answersActions
    } = this.props;

    if (!entries.length && isLoading) {
      return <Loader />;
    }

    return (
      <Loader loading={isLoading}>
        <div className={styles.wrapper}>
          <Tabs tabPosition="top">
            <TabPane tab="All project labels" key="1">
              <AnswersTab
                entries={entries}
                isMyLabels={false}
                title="All labels"
                projectId={match.params.projectId}
                onAnswerDelete={this.handleAnswerDelete}
                downloadDataInSquadFormat={answersActions.downloadDataInSquadFormat}
                emptyTableDescription={<span>This project doesn't have any labels for this project.</span>}
              />
            </TabPane>

            <TabPane tab="My labels" key="2">
              <AnswersTab
                entries={entries}
                userId={user.id}
                isMyLabels
                title="My labels"
                projectId={match.params.projectId}
                onAnswerDelete={this.handleAnswerDelete}
                downloadDataInSquadFormat={answersActions.downloadDataInSquadFormat}
                emptyTableDescription={<span>You don't have any labels for this project.</span>}
              />
            </TabPane>
          </Tabs>
        </div>
      </Loader>
    );
  }

  componentDidMount () {
    const {
      actions,
      match,
      isMyLabels
    } = this.props;

    actions.get(match.params.projectId, isMyLabels);
  }

  componentWillUnmount () {
    this.props.actions.reset();
  }
}

export default connect(
  state => ({
    answers: state.answers,
    user: state.user,
  }),
  dispatch => ({ actions: bindActionCreators(actions, dispatch) })
)(Answers);
