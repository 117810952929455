import React, { PureComponent } from 'react';
import { Button, Icon } from 'antd';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import moment from 'moment';
import ResetPassword from 'components/modals/ResetPassword';
import * as actions from 'store/actions/users';
import Loader from 'components/common/Loader';
import UserTable from 'components/UserTable';
import styles from './styles.module.scss';

class UserTab extends PureComponent {
  static propTypes = {
    users: PropTypes.array,
    getUsers: PropTypes.func,
    isLoading: PropTypes.bool,
    loginAs: PropTypes.func
  };

  static defaultProps = {
    users: [],
    getUsers: () => {},
    loginAs: () => {}
  };

  state = {
    isVisibleModal: false,
    selectedUser: null,
  };

  onToggleModal = (shouldOpen, selectedUser = null) => {
    this.setState({
      isVisibleModal: shouldOpen,
      selectedUser: selectedUser
    });
  };

  render() {
    const {
      users,
      loginAs,
      isLoading,
      resetPassword
    } = this.props;

    const {
      selectedUser,
      isVisibleModal
    } = this.state;

    if (!users.length && isLoading) {
      return <Loader />;
    }

    const additionalColumns = [
      {
        title: 'Role',
        dataIndex: 'role',
        key: 'role',
      },
      {
        title: 'Created',
        dataIndex: 'created_at',
        key: 'created_at',
        render: date => moment(date).format('DD-MM-YYYY')
      },
    ];

    return (
      <Loader loading={isLoading}>
        <UserTable
          users={users}
          actions={({ id }) => (
            <div className={styles.buttons}>
              <Button shape="circle" size="small" onClick={() => loginAs(id)}>
                <Icon type="login" />
              </Button>

              <Button shape="circle" size="small" onClick={() => this.onToggleModal(true, id)}>
                <Icon type="setting" />
              </Button>
            </div>
          )}
          additionalColumns={additionalColumns}
          emptyTableDescription={<span>This organization doesn't have any users yet.</span>}
        />

        <ResetPassword
          visible={isVisibleModal}
          title="Reset password"
          onClose={() => this.onToggleModal(false)}
          onSubmit={({ password }) => resetPassword(password, selectedUser)}
        />
      </Loader>
    );
  }

  componentDidMount() {
    const {
      organizationId,
      getUsers
    } = this.props;

    getUsers(organizationId);
  }
}

const mapStateToProps = ({ users }) => ({
  users: users.entries,
  isLoading: users.isLoading
});

const mapDispatchToProps = (dispatch) => ({
  getUsers: (organizationId) => dispatch(actions.getUsersByOrganizationId(organizationId)),
  loginAs: (userId) => dispatch(actions.loginAs(userId)),
  resetPassword: (password, userId) => dispatch(actions.resetPassword(password, userId))
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(UserTab);