import * as types from 'store/types/organizations';

export const get = () => ({
  type: types.GET
});

export const set = (entries) => ({
  type: types.SET,
  entries
});

export const deleteOrganization = (id, organizationIdx) => ({
  type: types.DELETE,
  id,
  organizationIdx
});

export const editOrganization = (newFields, organizationToUpdate) => ({
  type: types.EDIT,
  newFields,
  organizationToUpdate
});

export const updateOrganization = (newFields, organizationToUpdate) => ({
  type: types.UPDATE_ORGANIZATION,
  newFields,
  organizationToUpdate
});

export const deleteFromState = (organizationIdx) => ({
  type: types.DELETE_FROM_STATE,
  organizationIdx
});

export const getOrganization = (organizationId) => ({
  type: types.GET_ORGANIZATION,
  organizationId,
});

export const setOrganization = (organization) => ({
  type: types.SET_ORGANIZATION,
  organization,
});

export const setLoadingStatus = (status) => ({
  type: types.SET_LOADING_STATUS,
  status
});

export const reset = () => ({
  type: types.RESET,
});

export const setError = (error) => ({
  type: types.SET_ERROR,
  error
});

export const resetError = () => ({
  type: types.RESET_ERROR,
});