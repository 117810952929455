import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Link, withRouter } from 'react-router-dom';
import { Breadcrumb, Icon } from 'antd';
import cn from 'classnames';
import breadcrumbsMap from 'config/breadcrumbsMap';
import styles from './styles.module.scss';

class Breadcrumbs extends Component {
  getBreadcrumbItem = () => {
    const { location, activeProject } = this.props;

    const pathSnippets = location.pathname.split('/').filter(i => i);
    const extraBreadcrumbItems = [];

    pathSnippets.forEach((pathSnippet, idx) => {
      const url = `/${ pathSnippets.slice(0, idx + 1).join('/') }`;
      const urlToGetBreadcrumbs = url.replace(/\/\d+/g, '/:id');
      const getBreadcrumb = breadcrumbsMap[urlToGetBreadcrumbs];

      if (!getBreadcrumb) return null;

      let breadcrumb;

      switch (urlToGetBreadcrumbs) {
        case '/projects/:id':
          breadcrumb = getBreadcrumb(pathSnippet, activeProject);
          break;

        default:
          breadcrumb = getBreadcrumb(pathSnippet);
      }

      extraBreadcrumbItems.push(
        <Breadcrumb.Item key={url}>
          <Link to={url}>{ breadcrumb }</Link>
        </Breadcrumb.Item>
      );
    });

    return extraBreadcrumbItems;
  };

  render() {
    const extraBreadcrumbItems = this.getBreadcrumbItem();

    if (!extraBreadcrumbItems.length) {
      return null;
    }

    const classNames = cn(
      'container',
      styles.breadcrumbs
    );

    const breadcrumbItems = [
      <Breadcrumb.Item key="home">
        <Link to="/">
          <Icon type="home" />
        </Link>
      </Breadcrumb.Item>,
    ].concat(extraBreadcrumbItems);

    return (
      <div className={classNames}>
        <Breadcrumb>{breadcrumbItems}</Breadcrumb>
      </div>
    );
  }
}

export default withRouter(
  connect(
    state => ({
      activeProject: state.projects.activeProject
    })
    
  )(Breadcrumbs)
);
