import React, { PureComponent } from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Button, Icon } from 'antd';
import NavMenu from 'components/themes/common/NavMenu';
import links from 'config/links';
import * as actions from 'store/actions/auth';
import roles from 'core/constants/roles';
import styles from './styles.module.scss';
import logo from 'assets/logo/haystack-white-web.png';


class Header extends PureComponent {

  static propTypes = {
    user: PropTypes.object,
    actions: PropTypes.object,
  }

  render() {
    const { user, actions } = this.props;
    const isSuperAdmin = user.role === roles.SUPER_ADMIN;
    const linkToMainPage = isSuperAdmin ? links.organizations : links.projects;

    return (
      <header className={styles.wrapper}>
        <div className="container">
          <div className={styles.header}>
            <Link to={linkToMainPage}>

              <img  className={styles.logo} src={logo} alt="haystack annotation tool" />
            </Link>
            <div className={styles.userInfo}>
              { user.email }

              <Button shape="circle" size="small" onClick={actions.logout}>
                <Icon type="logout" />
              </Button>
            </div>
          </div>
        </div>

        { !isSuperAdmin && <NavMenu /> }
      </header>
    );
  }
}


export default connect(
  state => ({
    user: state.user,
  }),
  dispatch => ({
    actions: bindActionCreators(actions, dispatch),
  })
)(Header);
