import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { Button, Table, Popconfirm } from 'antd';
import moment from 'moment';
import { Link } from 'react-router-dom';
import { createLink } from 'core/utils/links';
import styles from './styles.module.scss';

class OrganizationsTable extends PureComponent {
  static propTypes = {
    isLoading: PropTypes.bool,
    organizations: PropTypes.array,
  };

  static defaultProps = {
    isLoading: false,
    organizations: [],
  };

  renderColumns = () => {
    const {
      onOpenModal,
      deleteOrganization
    } = this.props;

    return [
      {
        title: '№',
        dataIndex: 'index',
        key: 'index',
        width: 80,
        render: idx => idx + 1
      },
      {
        title: 'Organization name',
        dataIndex: 'name',
        key: 'name',
        width: 700
      },
      {
        title: 'Created',
        dataIndex: 'created_at',
        key: 'created_at',
        align: 'center',
        width: 100,
        render: date => moment(date).format('DD-MM-YYYY')
      },
      {
        title: 'Actions',
        key: 'action',
        align: 'center',
        render: ({ id, index, name }) => (
          <div className={styles.buttons}>
            <Button shape="circle" size="small">
              <Link to={createLink('organization', { id })}>
                <i className="icon-arrow-right" />
              </Link>
            </Button>

            <Button shape="circle" size="small" onClick={() => onOpenModal(true, { id, index })}>
              <i className="icon-edit" />
            </Button>

            <Popconfirm
              placement="leftTop"
              title={`Are you sure to delete "${name}" organization?`}
              onConfirm={() => deleteOrganization(id, index)}
              okText="Yes"
              cancelText="No"
            >
              <Button shape="circle" size="small">
                <i className="icon-delete" />
              </Button>
            </Popconfirm>
          </div>
        ),
        width: 120
      },
    ];
  };

  render() {
    const { organizations } = this.props;

    return (
      <Table
        bordered
        columns={this.renderColumns()}
        dataSource={organizations.map((item, i) => ({
          ...item,
          key: item.id,
          index: i
        }))}
      />
    );
  }
}

export default OrganizationsTable;