import { prefix } from 'core/utils/string';

const organizationPrefix = prefix('organization/');

export const GET_USERS = organizationPrefix('GET_USERS');
export const ADD_NEW_USER_TO_ORGANIZATION = organizationPrefix('ADD_NEW_USER_TO_ORGANIZATION');
export const SET_USERS = organizationPrefix('SET_USERS');
export const SET_NEW_USER = organizationPrefix('SET_NEW_USER');
export const REMOVE_NEW_USER = organizationPrefix('REMOVE_NEW_USER');
export const SET_LOADING_STATUS = organizationPrefix('SET_LOADING_STATUS');
export const SET_ERROR = organizationPrefix('SET_ERROR');
export const RESET_ERROR = organizationPrefix('RESET_ERROR');
export const RESET = organizationPrefix('RESET');
