import * as types from 'store/types/projects';

const initialState = {
  entries: [],
  activeProject: {
    id: null,
    name: null,
  },
  isLoading: false,
  error: '',
};

export default (state = initialState, action) => {
  switch (action.type) {
    case types.SET:
      return {
        ...state,
        entries: action.payload
      };
    case types.SET_ACTIVE_PROJECT:
      return {
        ...state,
        activeProject: action.payload
      };
    case types.ADD_PROJECT_ITEM:
      return {
        ...state,
        entries: [
          action.payload,
          ...state.entries
        ]
      };
    case types.REMOVE_PROJECT_ITEM: {
      return {
        ...state,
        entries: state.entries.filter(item => item.id !== action.id)
      };
    }

    case types.SET_LOADING_STATUS:
      return {
        ...state,
        isLoading: action.status
      };

    case types.SET_ERROR:
      return {
        ...state,
        error: action.error
      };

    case types.RESET_ERROR:
      return {
        ...state,
        error: ''
      };

    case types.RESET:
    default:
      return state;
  }
};
