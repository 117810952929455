import * as types from 'store/types/document';

export const get = (id) => ({
  type: types.GET,
  id
});

export const getByOffset = (projectId, offset) => ({
  type: types.GET_BY_OFFSET,
  projectId,
  offset
});

export const set = (payload) => ({
  type: types.SET,
  payload
});

export const updateFieldLive = (field, value) => ({
  type: types.UPDATE_FIELD_LIVE,
  field,
  value
});

export const deleteLabeler = () => ({
  type: types.DELETE_LABELER
});

export const updateField = (field, value) => ({
  type: types.UPDATE_FIELD,
  field,
  value
});

export const createQuestionWithAnswer = (question, answer) => ({
  type: types.CREATE_QUESTION_WITH_ANSWER,
  question,
  answer
});

export const addQuestion = (question) => ({
  type: types.ADD_QUESTION,
  question
});

export const updateQuestion = (id, question) => ({
  type: types.UPDATE_QUESTION,
  id,
  question
});

export const changeQuestion = (id, question) => ({
  type: types.CHANGE_QUESTION,
  id,
  question
});

export const deleteQuestionAnswers = (id) => ({
  type: types.DELETE_QUESTION_ANSWERS,
  id
});

export const deleteQuestion = (id) => ({
  type: types.DELETE_QUESTION,
  id
});

export const removeQuestion = (id) => ({
  type: types.REMOVE_QUESTION,
  id
});

// answers
export const getAnswers = (documentId) => ({
  type: types.GET_ANSWERS,
  documentId,
});

export const setAnswers = (answers) => ({
  type: types.SET_ANSWERS,
  answers,
});

export const updateAnswer = (questionId, data) => ({
  type: types.UPDATE_ANSWER,
  questionId,
  data
});

export const changeAnswer = (questionId, data) => ({
  type: types.CHANGE_ANSWER,
  questionId,
  data
});

export const deleteAnswer = (id) => ({
  type: types.DELETE_ANSWER,
  id
});

export const removeAnswer = (id) => ({
  type: types.REMOVE_ANSWER,
  id
});

export const toggleShowAllLabelsStatus = () => ({
  type: types.TOGGLE_SHOW_ALL_LABELS_STATUS,
});

export const setQuestionLoadingStatus = (status) => ({
  type: types.SET_QUESTION_LOADING_STATUS,
  status
});

export const setLoadingStatus = (status) => ({
  type: types.SET_LOADING_STATUS,
  status
});


export const reset = () => ({
  type: types.RESET,
});
