import * as types from 'store/types/organization';

export const addUserToOrganization = (userInfo) => ({
  type: types.ADD_NEW_USER_TO_ORGANIZATION,
  userInfo
});

export const getUsers = () => ({
  type: types.GET_USERS,
});

export const setUsers = (entries) => ({
  type: types.SET_USERS,
  entries
});

export const setNewUser = (user) => ({
  type: types.SET_NEW_USER,
  user
});

export const removeNewUser = () => ({
  type: types.REMOVE_NEW_USER
});

export const setLoadingStatus = (status) => ({
  type: types.SET_LOADING_STATUS,
  status
});

export const setError = (error) => ({
  type: types.SET_ERROR,
  error
});

export const resetError = () => ({
  type: types.RESET_ERROR
});

export const reset = () => ({
  type: types.RESET,
});