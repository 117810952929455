import React, { PureComponent, Fragment } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { Button } from 'antd';
import { bindActionCreators } from 'redux';
import * as actions from 'store/actions/projects';
import Loader from 'components/common/Loader';
import ProjectTable from 'components/shared/ProjectTable';
import CreateProjectModal from 'components/modals/CreateProject';
import styles from './styles.module.scss';

class Projects extends PureComponent {
  state = {
    isVisibleModal: false
  };

  static propTypes = {
    projects: PropTypes.object,
    createProject: PropTypes.func,
    resetError: PropTypes.func,
    deleteProject: PropTypes.func,
  };

  static defaultProps = {
    projects: {},
    createProject: () => {},
    resetError: () => {},
    deleteProject: () => {},
  };

  onToggleModal = (shouldOpen) => {
    this.setState({ isVisibleModal: shouldOpen });
  };

  onCreateProject = (data) => {
    this.props.actions.createProject(data);
    this.onToggleModal(false);
  };

  render() {
    const {
      projects: {
        entries: projects,
        isLoading,
        error
      },
      actions
    } = this.props;

    const { isVisibleModal } = this.state;

    if (!projects.length && isLoading) {
      return <Loader />;
    }

    return (
      <Loader loading={isLoading}>
        <div className={styles.wrapper}>
          <div className={styles.title}>
            <h1 className="h2">Projects</h1>

            <Button
              type="primary"
              onClick={() => this.onToggleModal(true)}
            >
              Create project
            </Button>
          </div>

          <ProjectTable
            projects={projects}
            actions={{deleteProject: actions.deleteProject}}
            withActionButtons
            emptyTableDescription={
              <Fragment>
                <span className="emptyTableText">To get started create your first project.</span>
                <span>You can use it to manage multiple questions, answers and documents combinations separatly.</span>
              </Fragment>
            }
          />

          <CreateProjectModal
            visible={isVisibleModal}
            title="Create project"
            error={error}
            onClose={() => this.onToggleModal(false)}
            onClearError={actions.resetError}
            onSubmit={this.onCreateProject}
          />
        </div>
      </Loader>
    );
  }
}

export default connect(
  (state) => ({
    projects: state.projects
  }),
  dispatch => ({
    actions: bindActionCreators(actions, dispatch)
  })
)(Projects);
