import * as types from 'store/types/superAdmin';

const initialState = {
  error: '',
  isLoading: false,
  isSuperAdminCreated: true,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case types.SET_ERROR:
      return {
        ...state,
        error: action.error
      };
    case types.RESET_ERROR:
      return {
        ...state,
        error: ''
      };
    case types.SET_LOADING_STATUS:
      return {
        ...state,
        isLoading: action.status
      };
    case types.CHANGE_SUPER_ADMIN_STATUS:
      return {
        ...state,
        isSuperAdminCreated: action.status
      };
    default:
      return state;
  }
};
