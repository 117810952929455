import { prefix } from 'core/utils/string';

const userPrefix = prefix('user/');

export const GET_INFO = userPrefix('GET_INFO');
export const SET_INFO = userPrefix('SET_INFO');

export const RESET = userPrefix('RESET');

export const SET_LOADING_STATUS = userPrefix('SET_LOADING_STATUS');
