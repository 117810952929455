import links from './links';

const breadcrumbsMap = () => {
  const linksBreadcrumbsFormat = { ...links };

  for (const link in linksBreadcrumbsFormat) {
    const linkValue = linksBreadcrumbsFormat[link];

    linksBreadcrumbsFormat[link] = linkValue.replace(/\/:\w+/gm, '/:id');
  }
  
  return({
    [linksBreadcrumbsFormat.project]: (id, activeProject) => {
      return activeProject ? activeProject.name : `Project ${ id }`;
    },

    [linksBreadcrumbsFormat.document]: (id) => `Document ${ id }`,
    [linksBreadcrumbsFormat.answers]: () => 'Labels',
    [linksBreadcrumbsFormat.questions]: () => 'Questions',
    
    [linksBreadcrumbsFormat.userManagement]: () => 'User management',
    [linksBreadcrumbsFormat.organization]: (id) => `Organization ${ id }`,
    [linksBreadcrumbsFormat.importDocuments]: () =>  `Import documents`,
    [linksBreadcrumbsFormat.importQuestions]: () =>  `Import questions`,
  });
};

export default breadcrumbsMap();