import { all, put, takeLatest, takeEvery, select } from 'redux-saga/effects';
import { message } from 'antd';
import roles from 'core/constants/roles';
import * as api from 'store/sagas/api';
import * as types from 'store/types/projects';
import * as actions from 'store/actions/projects';

export function* getProjects({ organizationId = null }) {
  const { role } = yield select(state => state.user);

  yield put(actions.setLoadingStatus(true));

  try {
    let projects = [];

    if (roles.SUPER_ADMIN === role && organizationId) {
      projects = yield api.get(`/superadmin/organizations/${ organizationId }/projects`);
    } else {
      projects = yield api.get('/projects');
    }

    yield put(actions.set(projects));
  } catch(error) {
    yield put(actions.setError(error.message));
  }

  yield put(actions.setLoadingStatus(false));
}

export function* createProject(data) {
  yield put(actions.setLoadingStatus(true));

  try {
    const project = yield api.post('/projects', data);

    yield put(actions.addProjectItem(project));
    message.success('Project has been created.');
  } catch(error) {
    message.error(error.message);
  }

  yield put(actions.setLoadingStatus(false));
}

export function* deleteProjects({ projectId, projectIdx }) {
  yield put(actions.setLoadingStatus(true));

  try {
    const { id } = yield api.del(`/projects/${projectId}`);

    if (id) {
      yield put(actions.removeProjectItem(projectId));
      message.success('Project has been deleted.');
    }
  } catch(error) {
    yield put(actions.setError(error.message));
    message.error(error.message);
  }

  yield put(actions.setLoadingStatus(false));
}

export default function* () {
  yield all([
    yield takeLatest(types.GET, getProjects),
    yield takeEvery(types.CREATE_PROJECT, ({ payload }) => createProject(payload)),
    yield takeEvery(types.DELETE_PROJECT, deleteProjects),
  ]);
}