import { prefix } from 'core/utils/string';

const authPrefix = prefix('auth/');

export const LOGIN = authPrefix('LOGIN');
export const LOGOUT = authPrefix('LOGOUT');
export const SET_AUTH_DATA = authPrefix('SET_AUTH_DATA');
export const SET_ERROR = authPrefix('SET_ERROR');
export const RESET_ERROR = authPrefix('RESET_ERROR');
export const DO_REGISTRATION = authPrefix('DO_REGISTRATION');

export const SET_LOADING_STATUS = authPrefix('SET_LOADING_STATUS');
