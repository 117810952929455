import { select, put as putEffect } from 'redux-saga/effects';
import api from 'core/api';
import * as actions from 'store/actions/auth';

export function * get (path, query = null) {
  return yield apiCall({}, path, 'GET', query);
}

export function * post (path, query = null, body = null) {
  return yield apiCall({}, path, 'POST', body ? query : null, body || query);
}

export function * put (path, query = null, body = null) {
  return yield apiCall({}, path, 'PUT', body ? query : null, body || query);
}

export function * patch (path, query = null, body = null) {
  return yield apiCall({}, path, 'PATCH', body ? query : null, body || query);
}

export function * del (path, query = null) {
  return yield apiCall({}, path, 'DELETE', query);
}

// other api functions
export function * upload (path, name, file, fileName, additionalData = {}) {  
  const options = { headers: { 'Content-Type': false } };
  const formData = new FormData();
  formData.append(name, file, fileName);
  
  // apply additional data
  for (let key in additionalData) {
    formData.append(key, additionalData[key])
  }

  return yield apiCall(options, path, 'POST', null, formData);
}


function * apiCall (options = {}, path, method, query, body) {
  const apiInstance = api();
  const { token } = yield select(state => state.auth);

  // set additional headers if passed
  if (options.headers) {
    apiInstance.setHeaders(options.headers);
  }
  
  if (token) {
    apiInstance.setAuthorization(`Bearer ${token}`);
  }

  let result;
  try {
    result = yield apiInstance.call(path, method, query, body);
  } catch (error) {
    if (apiInstance.response && apiInstance.response.status === 401) {
      yield putEffect(actions.logout());
    }

    throw error;
  }

  return result;
}
