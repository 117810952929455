import { prefix } from 'core/utils/string';

const documentsPrefix = prefix('documents/');

export const GET = documentsPrefix('GET');
export const SET = documentsPrefix('SET');

export const GET_ALL = documentsPrefix('GET_ALL');

export const DELETE_DOCUMENT = documentsPrefix('DELETE_DOCUMENT'); // for saga action
export const REMOVE_DOCUMENT = documentsPrefix('REMOVE_DOCUMENT');

export const SET_LOADING_STATUS = documentsPrefix('SET_LOADING_STATUS');

export const RESET = documentsPrefix('RESET');
