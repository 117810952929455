import React, { PureComponent } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import cn from "classnames";
import { bindActionCreators } from "redux";
import { Upload, Icon, Alert, message, Row, Col } from "antd";
import { Loader } from "components/common";
import * as actions from "store/actions/imports";
import styles from "./styles.module.scss";

export class QuestionsImport extends PureComponent {
  static propTypes = {
    activeProject: PropTypes.object,
    questions: PropTypes.object,
    actions: PropTypes.object,
  };

  static defaultProps = {
    activeProject: {},
    questions: {},
    actions: {},
  };

  handleFileUpload = ({ file }) => {
    this.props.actions.uploadQuestionsCsv(this.props.activeProject.id, file);
  };

  render() {
    const { file, errors, isUploading } = this.props.questions;

    return (
      <div className={styles.wrapper}>
        <div className={styles.title}>
          <h1 className="h2">Import questions</h1>
        </div>

        <Row>
          <Col span={18}>
            {!isUploading && !!errors.length && (
              <Alert
                className={styles.alert}
                message={
                  <>
                    <strong>{file.name}</strong>
                    {errors.map((i) => (
                      <div>
                        Row {i.currentRow} - {i.error}
                      </div>
                    ))}
                  </>
                }
                type="error"
                closable
                onClose={this.props.actions.resetQuestionsCsv}
              />
            )}

            <Loader loading={isUploading} className={styles.dropzoneWrapper}>
              <Upload.Dragger
                className={cn(styles.dropzone, {
                  [styles.withOpacity]: isUploading,
                })}
                customRequest={this.handleFileUpload}
                name="file"
                accept=".csv"
                multiple={false}
                showUploadList={false}
                beforeUpload={(file) => {
                  const isCSV =
                    /csv$/.test(file.type) || /excel$/.test(file.type);
                  if (!isCSV) {
                    console.error(
                      "could not accept the following file because of the mime type",
                      { file }
                    );
                    message.info(`Please choose a CSV file for upload.`);
                    return false;
                  }
                }}
              >
                <p className="ant-upload-drag-icon">
                  <Icon type="file-add" className={styles.dropzoneIcon} />
                </p>
                <p className="ant-upload-text">
                  Click or drag csv file to this area to upload questions
                </p>
              </Upload.Dragger>
            </Loader>
          </Col>
          <Col span={6}>
            <Alert
              className={styles.requirementsNote}
              description={
                <p>
                  The question CSV import can have the following columns:
                  <br />
                  <br />
                  <strong>question:</strong>
                  <br /> The Question itself.
                  <br />
                  <br />
                  <strong>document_identifier (optional):</strong>
                  <br /> If you want to to import a question for a specific
                  document, please use the unique document identifier here,
                  which you used in the document import.
                  <br />
                  <br />
                  <br />
                  <strong>question_identifier (optional):</strong> An
                  indentifier for the question, which will be shown in the squad
                  export.
                </p>
              }
              type="info"
            />
          </Col>
        </Row>
      </div>
    );
  }

  componentWillUnmount() {
    this.props.actions.resetQuestionsCsv();
  }
}

export default connect(
  (state) => ({
    activeProject: state.projects.activeProject,
    questions: state.imports.questionsCsv,
  }),
  (dispatch) => ({
    actions: bindActionCreators(actions, dispatch),
  })
)(QuestionsImport);
