import React, { PureComponent, Fragment } from 'react';
import PropTypes from 'prop-types';
import { Modal, Input, message } from 'antd';
import { Scrollbars } from 'react-custom-scrollbars';
import { hexToRGBA } from 'core/utils/color';
import { getDescFilteredSelections } from 'core/helpers/range';
import AddQuestionModal from 'components/modals/AddQuestion';
import styles from './styles.module.scss';

export class TextSelectionArea extends PureComponent {
  constructor (props) {
    super(props);

    this.scrollContainer = React.createRef();
    this.textContainer = React.createRef();
    this.range = null;
    this.state = {
      search: '',
      lastRangeObj: null,
      isQuestionModalVisible: false,
    };
  }

  static propTypes = {
    editable: PropTypes.bool,
    defaultSet: PropTypes.array,
    document: PropTypes.object,
    answers: PropTypes.array,
    question: PropTypes.object,
    maxTextHeight: PropTypes.string,
    answerWithCategoryMode: PropTypes.bool,
    createQuestionAvailable: PropTypes.bool,
    onSearch: PropTypes.func,
    onSelect: PropTypes.func,
    onSelectionDelete: PropTypes.func,
    onQuestionWithAnswerCreate: PropTypes.func,
  }

  static defaultProps = {
    editable: true,
    defaultSet: [],
    document: { id: null, text: '', status: false },
    answers: [],
    maxTextHeight: 'auto',
    answerWithCategoryMode: false,
    createQuestionAvailable: false,
    onSearch: () => {},
    onSelect: () => {},
    onSelectionDelete: () => {},
    onQuestionWithAnswerCreate: () => {},
  }

  clearSelections = () => {
    const textContainer = this.textContainer.current;
    // reset to initial text
    textContainer.innerHTML = this.props.document.text;
  }

  setSelection = () => {
    const { answers, question } = this.props;
    const answer = answers.find(i => i.question_id === question.id);
    const selection = getSelection();

    if (!answer) return;

    selection.addRange(this.objToRange(answer));
    const node = document.createElement('span');
    node.setAttribute('style', `background-color: ${hexToRGBA(question.data.color, 0.3)};`);
    selection.removeAllRanges();

    try {
      this.range.surroundContents(node);
    } catch (e) {
      // eslint-disable-next-line no-console
      console.error(e);
    }
  }

  applyDefaultSet = () => {
    const selections = getDescFilteredSelections(this.props.defaultSet);
    const selection = getSelection();
    const overlappedLength = selections.overlapped.length;

    selections.valid.forEach(el => {
      selection.addRange(this.objToRange(el));
      const node = document.createElement('span');
      node.setAttribute('style', `background-color: ${hexToRGBA(el.color, 0.3)};`);

      try {
        this.range.surroundContents(node);
      } catch (e) {
        // eslint-disable-next-line no-console
        console.error(e);
      }
    });

    selection.removeAllRanges();

    if (overlappedLength) {
      message.info(`${overlappedLength} ${overlappedLength > 1 ? 'selections are' : 'selection is'} not shown because it's overlapping with another questions.`);
    }
  }

  objToRange = (rangeStr) => {
    this.range = document.createRange();
    const nodes = this.textContainer.current.childNodes;
    try {
      let startNodeIndex = null;
      let endNodeIndex = null;
      let currentLength = 0;

      for (let i = 0; i < nodes.length; i++) {
        const node = nodes[i];

        currentLength += node.length;

        // check for startNodeIndex
        if (node.nodeName !== 'SPAN' && startNodeIndex === null && rangeStr.start_offset <= currentLength) {
          startNodeIndex = i;
        }

        // check for endNodeIndex
        if (node.nodeName !== 'SPAN' && endNodeIndex === null && rangeStr.end_offset <= currentLength) {
          endNodeIndex = i;
        }
      }

      this.range.setStart(nodes[startNodeIndex], rangeStr.start_offset - startNodeIndex * 65536);
      this.range.setEnd(nodes[endNodeIndex], rangeStr.end_offset - endNodeIndex * 65536);
    } catch (e) {
      // eslint-disable-next-line no-console
      console.error(e);
    }

    return this.range;
  }

  handleSelect = () => {
    const { editable, document: doc, question, createQuestionAvailable, onSelect } = this.props;
    const selection = document.getSelection();

    // return if document is not editable or something wrong with selection
    // and it has no length
    if (!editable || selection.isCollapsed) return;

    this.range = selection.getRangeAt(0);
    // quit if selection is empty
    if (this.range.collapsed) return;

    let newRange = {
      start_offset: this.range.startOffset,
      end_offset: this.range.endOffset,
    };


    if (question.id || (!question.id && createQuestionAvailable)) {

      try {
        const node = document.createElement('span');
        node.setAttribute('style', `background-color: ${hexToRGBA(question.data.color, 0.3)};`);

        // add span around the selection
        this.range.surroundContents(node);

        const childNodes = this.range.commonAncestorContainer.childNodes;

        // check if range has more nodes than 3
        // more than 3 it means that text was broken to smaller chanks
        if (childNodes.length > 3) {
          let startOffset = 0;
          let selectionLength = 0;
          // and to count new newRange - iterate through all text chunks and increment its length until span
          // ('span' means current selection)
          for (let i = 0; i < childNodes.length; i++) {
            const node = childNodes[i];
            // if not span and span wasn't met before
            if (node.nodeName !== 'SPAN' && !selectionLength) {
              startOffset += node.length;
            } else if (node.nodeName === 'SPAN') {
              selectionLength = node.innerText.length;
            }
          }

          newRange = {
            start_offset: startOffset,
            end_offset: startOffset + selectionLength,
          };
        }

      } finally {
        const selected_text = doc.text.slice(newRange.start_offset, newRange.end_offset);

        const rangeObj = { ...newRange, selected_text };

        this.setState({
          lastRangeObj: rangeObj
        });

        if (question.id) {
          // if question selected - save selection
          onSelect(rangeObj);
        } else {
          // if no question selected -  open creation modal
          this.openQuestionModal();
        }

        document.getSelection().removeAllRanges();
      }

    }
  };

  showDeleteConfirm = (answerId) => {
    Modal.confirm({
      centered: true,
      title: 'Are you sure delete this answer?',
      okText: 'Yes',
      okType: 'danger',
      cancelText: 'No',
      onOk: () => {
        this.clearSelections();
        this.props.onSelectionDelete(answerId);
      }
    });
  }

  handleSelectionClick = (e) => {
    const { editable, question, answers } = this.props;
    const answer = answers.find(i => i.question_id === question.id);

    // return if no question selected
    if (!question.id || !editable) return;

    if (e.target.tagName === 'SPAN') {
      this.showDeleteConfirm(answer.id);
    } else {
      this.clearSelections();
    }
  }

  openQuestionModal = () => {
    this.setState({
      isQuestionModalVisible: true,
    });
  }

  closeQuestionModal = () => {
    this.setState({
      isQuestionModalVisible: false,
      lastRangeObj: null
    });

    this.clearSelections();
  }

  handleQuestionSubmit = (question, answer) => {
    this.props.onQuestionWithAnswerCreate(question, { ...this.state.lastRangeObj, ...answer });

    this.closeQuestionModal();
  }

  // search handler
  handleSearchChange = ({ target }) => {
    const value = target.value;
    this.setState({ search: value });

    this.props.onSearch();

    // clear all
    this.clearSelections();

    if (value.length) {
      const textContainer = this.textContainer.current;
      const text = textContainer.innerText;
      const newText = text.replace(new RegExp(value, 'gi'), (match) => `<mark>${match}</mark>`);

      // add seletions matches
      textContainer.innerHTML = newText;
    } else {
      // add default selections or question answer
      if (this.props.question.id) {
        this.setSelection();
      } else {
        this.applyDefaultSet();
      }
    }
  }

  // scroll to answer handler
  scrollToActiveAnswer = () => {
    // check if document has answer marked
    const node = this.textContainer.current.querySelector('span');
    const position = node ? node.offsetTop : null;
    //
    if (position !== null) {
      // scroll to the answer
      this.scrollContainer.current.scrollTop(position);
    }
  }

  componentDidMount () {
    const { document, question } = this.props;
    const { answers = [] } = document;
    const answer = answers.find(i => i.question_id === question.id);

    // if answer exists
    if (question.id && answer) {
      this.setSelection();
    }

    // add mouse event listener
    this.textContainer.current.addEventListener('mousedown', this.handleSelectionClick);
    this.textContainer.current.addEventListener('mouseup', this.handleSelect);
  }

  componentDidUpdate (prevProps) {
    const { document, question, answers, defaultSet, createQuestionAvailable } = this.props;
    const prevSetTextString = prevProps.defaultSet.reduce((acc, i) => `${acc}${i.selected_text}`, '');
    const setTextString = defaultSet.reduce((acc, i) => `${acc}${i.selected_text}`, '');
    // check if question or answers was changed
    if (question.id && (question.id !== prevProps.question.id || answers.length !== prevProps.answers.length)) {
      // clear old selections
      this.clearSelections();

      // clear prev search search results
      this.setState({ search: '' });

      // and set new
      this.setSelection();

      // scroll to active answer
      this.scrollToActiveAnswer();
    }

    // defaultSet should apply if:
    // create question mode is not available and one of next is true:
    // - document changed
    // - active question was reset
    // - document the same, but no question selected and defaultSet length or colors changed
    if (!createQuestionAvailable &&
      (document.id !== prevProps.document.id ||
      (!question.id && prevProps.question.id) ||
      (document.id === prevProps.document.id && !question.id &&
        (defaultSet.length !== prevProps.defaultSet.length || setTextString !== prevSetTextString)))
    ) {

      this.clearSelections();
      this.applyDefaultSet();
    }

    // clear selections if createQuestionAvailable mode become active
    if (!prevProps.createQuestionAvailable && createQuestionAvailable) {
      this.clearSelections();
    }

  }

  render() {
    const { document, maxTextHeight, answerWithCategoryMode } = this.props;
    const { isQuestionModalVisible, search } = this.state;

    // avoid opening html tags destroying our UI
    const documentText = document.text;

    return (
      <Fragment>
        <div className={styles.wrapper}>
          <div className={styles.title}>
            <h2>Annotation Document</h2>
            <Input.Search
              style={{ width: 200 }}
              placeholder="Search"
              value={search}
              onChange={this.handleSearchChange}
            />
          </div>

          <Scrollbars
            autoHeight
            autoHeightMax={maxTextHeight}
            className={styles.textContainer}
            ref={this.scrollContainer}
          >
            <div
              ref={this.textContainer}
              className={styles.text}
              dangerouslySetInnerHTML={{ __html: documentText }}
            />
          </Scrollbars>
        </div>

        {
          isQuestionModalVisible && (
            <AddQuestionModal
              title="Add Custom Question"
              isDocumentQuestion={true}
              withAnswerCategorySelect={answerWithCategoryMode}
              onSubmit={this.handleQuestionSubmit}
              onClose={this.closeQuestionModal}
            />
          )
        }
      </Fragment>
    );
  }

  componentWillUnmount () {
    this.textContainer.current.removeEventListener('mousedown', this.handleSelectionClick);
    this.textContainer.current.removeEventListener('mouseup', this.handleSelect);
  }
}

export default TextSelectionArea;
