import * as types from './../types/auth';

export const login = (email, password) => ({
  type: types.LOGIN,
  email,
  password
});

export const logout = () => ({
  type: types.LOGOUT
});

export const setAuthData = (token) => ({
  type: types.SET_AUTH_DATA,
  token
});

export const setError = (error) => ({
  type: types.SET_ERROR,
  error
});

export const resetError = () => ({
  type: types.RESET_ERROR
});

export const setLoadingStatus = (status) => ({
  type: types.SET_LOADING_STATUS,
  status
});

export const doRegistration = (registrationData) => ({
  type: types.DO_REGISTRATION,
  registrationData
});