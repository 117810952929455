import React, { PureComponent } from 'react';
import { Route } from 'react-router-dom';
import { connect } from 'react-redux';
import App from 'components/App';
import Provider from 'components/Provider';
import links from 'config/links';
import { MainTemplate } from 'components/themes';
import Login from 'components/Login';
import Registration from 'components/Registration';
import CreateSuperAdmin from 'components/CreateSuperAdmin';
import SuperAdminRoutes from './SuperAdminRoutes';
import roles from 'core/constants/roles';
import UserRoutes from './UserRoutes';

class Routes extends PureComponent {
  render () {
    const { user } = this.props;

    return (
      <App>
        <Route path={links.login} component={Login} />
        <Route path={links.registration} component={Registration} />
        <Route path={links.createSuperAdmin} component={CreateSuperAdmin} />

        <Provider>
          <MainTemplate>
            {
              user.role === roles.SUPER_ADMIN ? <SuperAdminRoutes /> : <UserRoutes />
            }
          </MainTemplate>
        </Provider>
      </App>
    );
  }
}

export default connect(
  state => ({
    user: state.user
  })
)(Routes);
