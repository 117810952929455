import { getRandomColor } from 'core/utils/color';
import * as documentTypes from 'store/types/document';
import * as types from 'store/types/questions';

const initialState = {
  entries: [],
  pagination: {},
  entriesCategoryMap: {},
  availableCategories: ['U'],
  categoriesKeys: [],

  // all - standard + current document questions
  all: [],
  activeTab: null,

  isLoading: false
};

export default (state = initialState, action) => {
  switch (action.type) {
    case types.SET:
      return {
        ...state,
        ...action.payload
      };
    case types.SET_MORE:
      return {
        ...state,
        entries: [
          ...state.entries,
          ...action.payload.entries
        ],
        pagination: action.payload.pagination
      };
    case types.SET_ALL: {
      const questions = action.payload.entries.map((item) => ({
        ...item,
        color: getRandomColor()
      }));

      const entriesCategoryMap = questions.reduce((acc, item) => {
        const prevValues = acc.hasOwnProperty(item.category) ? acc[item.category] : [];
        acc[item.category] = [...prevValues, item];
        return acc;
      }, {});

      const categories = [...Object.keys(entriesCategoryMap), ...state.availableCategories];
      const sortedEntries = [...categories].sort((a, b) => a.charCodeAt() - b.charCodeAt());

      return {
        ...state,
        entries: action.payload.entries,
        pagination: action.payload.pagination,
        entriesCategoryMap,
        availableCategories: categories,
        categoriesKeys: categories.map(i => i.charCodeAt()),
        all: [
          ...questions,
          ...state.all,
        ],
        activeTab: sortedEntries[0]
      };
    }
    case types.SET_ACTIVE_TAB:
      return {
        ...state,
        activeTab: action.tab
      };
    case types.ADD_QUESTION:
      return {
        ...state,
        entries: [
          ...state.entries,
          action.question
        ],
        pagination: {
          ...state.pagination,
          count: state.pagination.count + 1
        }
      };
    case types.CHANGE_QUESTION:
      return {
        ...state,
        entries: state.entries.map(item => {
          if (item.id === action.id) {
            return { ...item, ...action.question };
          }
          return item;
        })
      };
    case types.REMOVE_QUESTION:
      return {
        ...state,
        entries: state.entries.filter(item => item.id !== action.id),
        pagination: {
          ...state.pagination,
          count: state.pagination.count - 1
        }
      };
    case types.SET_LOADING_STATUS:
      return {
        ...state,
        isLoading: action.status,
      };
    case types.RESET:
      return {
        ...initialState,
      };

    // documentTypes
    case documentTypes.SET: {
      const standardQuestions = [...state.all].splice(0, state.entries.length);
      const documentQuestions = action.payload.questions.map((item) => ({
        ...item,
        color: getRandomColor(),
      }));

      return {
        ...state,
        entriesCategoryMap: {
          ...state.entriesCategoryMap,
          U: documentQuestions
        },
        all: [
          ...standardQuestions,
          ...documentQuestions
        ]
      };
    }
    case documentTypes.ADD_QUESTION:
      const newQuestion = { ...action.question, key: state.all.length };
      return {
        ...state,
        entriesCategoryMap: {
          ...state.entriesCategoryMap,
          U: state.entriesCategoryMap.hasOwnProperty('U')
            ? [...state.entriesCategoryMap['U'], newQuestion]
            : []
        },
        all: [...state.all, newQuestion]
      };
    case documentTypes.REMOVE_QUESTION:
      return {
        ...state,
        entriesCategoryMap: {
          ...state.entriesCategoryMap,
          U: state.entriesCategoryMap.hasOwnProperty('U')
            ? state.entriesCategoryMap['U'].filter(item => item.id !== action.id)
            : []
        },
        all: state.all.filter(item => item.id !== action.id)
      };
    case documentTypes.CHANGE_QUESTION:
      return {
        ...state,
        all: state.all.map(item => {
          if (item.id === action.id) {
            return { ...item, ...action.question };
          }
          return item;
        })
      };
    default:
      return state;
  }
};
