import React, { PureComponent, Fragment } from 'react';
import PropTypes from 'prop-types';
import { Modal, Button, Alert } from 'antd';
import CreateUserForm from './Form';
import styles from './styles.module.scss';

class CreateUserModal extends PureComponent {
  static propTypes = {
    title: PropTypes.string,
    error: PropTypes.string,
    newUserInOrganization: PropTypes.object,
    visible: PropTypes.bool,
    onClose: PropTypes.func,
    onSubmit: PropTypes.func,
    onClearError: PropTypes.func
  };

  static defaultProps = {
    title: '',
    error: '',
    newUserInOrganization: null,
    visible: true,
    onClose: () => {},
    onClearError: () => {},
    onSubmit: () => {}
  };

  render() {
    const {
      visible,
      title,
      onClose,
      onClearError,
      error,
      onSubmit,
      newUserInOrganization
    } = this.props;

    return (
      <Modal
        visible={visible}
        title={title}
        width={400}
        centered
        footer={false}
        destroyOnClose
        onCancel={onClose}
      >
        {
          newUserInOrganization ?
            <NewUserInfo
              onClose={onClose}
              newUser={newUserInOrganization}
            /> :
            <CreateUserForm
              error={error}
              onClearError={onClearError}
              onSubmit={onSubmit}
            />
        }
      </Modal>
    );
  }
}

const NewUserInfo = ({ newUser, onClose }) => (
  <Fragment>
    <h3 className={styles.newUser__title}>
      User was created:
    </h3>

    <div className={styles.newUser__info}>
      <span>Email:</span>
      <span>{newUser.email}</span>
    </div>

    <div className={styles.newUser__info}>
      <span>Password:</span>
      <span>{newUser.password}</span>
    </div>

    <Alert
      message="Please share this password now, this password can not be restored."
      type="warning"
      closable
    />

    <Button
      className={styles.newUser__button}
      block
      type="primary"
      onClick={onClose}
    >
      OK
    </Button>
  </Fragment>
);

export default CreateUserModal;
