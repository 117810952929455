import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { Router } from 'react-router-dom';
import createSagaMiddleware from 'redux-saga';
import { createStore, compose, applyMiddleware } from 'redux';
import * as Sentry from '@sentry/browser';

import history from './history';
import Routes from './routes';
import rootReducer from './store/reducers';
import rootSaga from './store/sagas';

import './assets/styles/global.scss';

// eslint-disable-next-line no-console
console.log('%c env: ', 'color: #bada55', process.env.REACT_APP_ENV);

// enabled only for production
if (process.env.REACT_APP_ENV === 'production') {
  Sentry.init({ dsn: 'https://4509e522a1734e8c9bfa3f7215e93885@sentry.io/1769036' });
}

const composeEnhancer =
  // disable store devtools for production
  (process.env.REACT_APP_ENV !== 'production' && window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__) || compose;


const sagaMiddleware = createSagaMiddleware();

const store = createStore(
  rootReducer,
  composeEnhancer(
    applyMiddleware(sagaMiddleware)
  )
);

sagaMiddleware.run(rootSaga);

ReactDOM.render(
  <Provider store={store}>
    <Router history={history}>
      <Routes />
    </Router>
  </Provider>,
  document.getElementById('root')
);
