import React, { PureComponent } from 'react';
import { Form, Input, Select, Button, Icon, Tooltip } from 'antd';
import PropTypes from 'prop-types';
import { annotationModes } from 'core/constants/types';
import styles from './styles.module.scss';

class CreateProjectForm extends PureComponent {
    static propTypes = {
      form: PropTypes.object,
      error: PropTypes.string,
      onSubmit: PropTypes.func,
      onClearError: PropTypes.func,
    };

    handleSubmit = (e) => {
      e.preventDefault();

      this.props.form.validateFields((err, values) => {
        if (!err) {
          this.props.onSubmit(values);
        }
      });
    };

    render() {
      const {
        error,
        form: {
          getFieldDecorator
        },
      } = this.props;

      return (
        <Form onSubmit={this.handleSubmit} hideRequiredMark colon={false}>
          <Form.Item label="Name">
            {getFieldDecorator('name', {
              rules: [{ required: true, message: 'Please insert a project name!' }],
            })(<Input placeholder="Project name" type="text" autoFocus />)}
          </Form.Item>

          <Form.Item label={
            <div className={styles.annotationModeLabel}>
              <span>Annotation mode</span>
              <Tooltip
                title="(Here will be annotation mode description)"
              >
                <Icon type="info-circle" />
              </Tooltip>
            </div>
          }>
            {getFieldDecorator('annotation_mode', {
              initialValue: annotationModes.DEFAULT,
              rules: [{ required: true, message: 'Please select an annotation mode!' }],
            })(
            <Select placeholder="Annotation mode">
              <Select.Option value={annotationModes.DEFAULT}>Default</Select.Option>
              <Select.Option value={annotationModes.ANSWER_CATEGORY_MODE}>Answer category</Select.Option>
            </Select>
            )}
          </Form.Item>

          <Button
            block
            type="primary"
            onClick={this.handleSubmit}
          >
            Create project
          </Button>

          {error && <div className={styles.error}>{error}</div>}
        </Form>
      );
    }
}

export default Form.create({
  onFieldsChange: (props) => {
    if (props.error) {
      props.onClearError();
    }
  }
})(CreateProjectForm);
