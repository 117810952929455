
// returns desc ordered selections by 'end_offset' field
export const getDescSelections = (selections = []) =>
  [...selections].sort((el1, el2) => el2.end_offset - el1.end_offset);

// returns filtered selections - { valid: [], overlapped: [] }
// based on 'start_offset', 'end_offset' items fields
export const getDescFilteredSelections = (arr = []) => {
  const selections = getDescSelections(arr);
  // return if not selections passes
  if (!selections.length) return { valid: [], overlapped: [] };

  const result = selections.reduce((acc, curr, i) => {
    // add the very first item to 'valid'
    if (i === 0) {
      acc.valid = [...acc.valid, curr];
    } else if (curr.end_offset < acc.valid[acc.valid.length-1].start_offset) {
      acc.valid = [...acc.valid, curr];
    } else {
      acc.overlapped = [...acc.overlapped, curr];
    }

    return acc;
  }, { valid: [], overlapped: [] });

  return result;
};
