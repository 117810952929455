import { all, fork } from 'redux-saga/effects';
import authSaga from './auth';
import userSaga from './user';
import superAdmin from './superAdmin';
import documentsSaga from './documents';
import questionsSaga from './questions';
import answersSaga from './answers';
import documentSaga from './document';
import organizationsSaga from './organizations';
import usersSaga from './users';
import projectsSaga from './projects';
import organizationSaga from './organization';
import importsSaga from './imports';

export default function* rootSaga() {
  yield all([
    fork(authSaga),
    fork(userSaga),
    fork(superAdmin),
    fork(documentSaga),
    fork(documentsSaga),
    fork(questionsSaga),
    fork(answersSaga),
    fork(organizationsSaga),
    fork(usersSaga),
    fork(projectsSaga),
    fork(organizationSaga),
    fork(importsSaga),
  ]);
}
