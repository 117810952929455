
import React, { PureComponent } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import cn from "classnames";
import { bindActionCreators } from "redux";
import { Upload, Icon, message, Progress, Alert } from "antd";
import { Loader } from "components/common";
import * as actions from "store/actions/imports";
import styles from "./styles.module.scss";
import { Tabs, Col, Row } from "antd";

const { TabPane } = Tabs;

export class DocumentsImport extends PureComponent {
  state = {
    fileList: [],
  };

  static propTypes = {
    activeProject: PropTypes.object,
    documents: PropTypes.object,
    documentsCsv: PropTypes.object,
    actions: PropTypes.object,
  };

  static defaultProps = {
    activeProject: {},
    documents: {},
    documentsCsv: {},
    actions: {},
  };

  handleCsvUpload = ({ file }) => {
    this.props.actions.uploadDocumentsCsv(this.props.activeProject.id, file);
  };

  handleDocumentsUpload = ({ file }) => {
    const { fileList } = this.state;
    const isLast = file === fileList[fileList.length - 1];

    if (!isLast) return;

    this.props.actions.uploadDocuments(this.props.activeProject.id, fileList);
    this.setState({ fileList: [] });
  };

  render() {
    const {
      documents: { progress, isUploading },
      documentsCsv: { file, errors, isUploading: isCsvUploading },
    } = this.props;

    return (
      <div className={styles.wrapper}>
        <div className={styles.title}>
          <h1 className="h2">Import documents</h1>
        </div>

        <Tabs defaultActiveKey="1">
          <TabPane tab="TXT Upload" key="1">
            <Row>
              <Col span={18}>
                <div className={styles.dropzoneWrapper}>
                  <Upload.Dragger
                    className={cn(styles.dropzone, {
                      [styles.withOpacity]: isUploading,
                    })}
                    customRequest={this.handleDocumentsUpload}
                    name="files"
                    accept=".txt"
                    multiple={true}
                    showUploadList={false}
                    beforeUpload={(file) => {
                      if (!/text\/plain$/.test(file.type)) {
                        message.info(
                          `${file.name} has a wrong file format. The file will not be uploaded.`
                        );
                        return false;
                      }

                      this.setState((state) => ({
                        fileList: [...state.fileList, file],
                      }));
                    }}
                  >
                    <p className="ant-upload-drag-icon">
                      <Icon type="file-add" className={styles.dropzoneIcon} />
                    </p>
                    <p className="ant-upload-text">
                      Click or drag files to this area to upload documents
                    </p>
                  </Upload.Dragger>
                  {isUploading && (
                    <div className={styles.progressBarWrapper}>
                      <div
                        className={styles.progressBarText}
                      >{`${progress.completedFiles} / ${progress.files.length} uploaded`}</div>
                      <Progress
                        type="circle"
                        percent={Math.round(
                          (progress.uploadedSize * 100) / progress.totalSize
                        )}
                        format={(percent) => `${percent}%`}
                        strokeWidth={3}
                      />
                    </div>
                  )}
                </div>
              </Col>
              <Col span={6}><Alert
              className={styles.requirementsNote}
              description={
                <p>
                  Please select files you want to upload into the annotation tool.

                  <br/> Please note: Currently only plain text is supported for annotation.

                </p>
              }
              type="info"
            /></Col>
            </Row>
          </TabPane>
          <TabPane tab="CSV Batch Upload" key="2">
          <Row>
              <Col span={18}>
              {!isUploading && !!errors.length && (
              <Alert
                className={styles.alert}
                message={
                  <>
                    <strong>{file.name}</strong>
                    {errors.map((i) => (
                      <div>
                        Row {i.currentRow} - {i.error}
                      </div>
                    ))}
                  </>
                }
                type="error"
                closable
                onClose={this.props.actions.resetDocumentsCsv}
              />
            )}

            <Loader loading={isCsvUploading} className={styles.dropzoneWrapper}>
              <Upload.Dragger
                className={cn(styles.dropzone, styles.csvDropzone, {
                  [styles.withOpacity]: isCsvUploading,
                })}
                customRequest={this.handleCsvUpload}
                name="file"
                accept=".csv"
                multiple={false}
                showUploadList={false}
                beforeUpload={(file) => {
                  console.log({file});
                  // text/csv or application/vnd.ms-excel
                  const isCSV = /csv$/.test(file.type) || /excel$/.test(file.type);
                  if (!isCSV) {
                    console.error('could not accept the following file because of the mime type', {file});
                    message.info(`Please choose a CSV file for upload.`);
                    return false;
                  }
                }}
              >
                <p className="ant-upload-drag-icon">
                  <Icon type="file-add" className={styles.dropzoneIcon} />
                </p>
                <p className="ant-upload-text">

                </p>
              </Upload.Dragger>
            </Loader>
              </Col>
              <Col span={6}>
            <Alert
              className={styles.requirementsNote}
              description={
                <p>
                  <p>Click or drag csv file to this area to upload documents</p>
                  <br /><br/>
                  <strong>document_text:</strong><br/> The text of the document.
                  <br /><br/>

                  <strong>document_identifier (optional):</strong><br/> This
                  identifier can be used in the export and also to map questions
                  from the CSV import exactly for this document.
                  <br /><br/>
                  <strong>order (integer, optional):</strong><br/> An number which is
                  reponsible for the later order of this document.
                  <br />
                  <br />
                  <br />
                  <p>
                    Please use a CSV file with comma separation, for example:
                    document_identifier,document_text \n aaaa, bbbbb
                  </p>
                </p>
              }
              type="info"
            />


            </Col>
            </Row>
          </TabPane>
        </Tabs>
      </div>
    );
  }

  componentWillUnmount() {
    this.props.actions.resetDocumentsCsv();
  }
}

export default connect(
  (state) => ({
    activeProject: state.projects.activeProject,
    documents: state.imports.documents,
    documentsCsv: state.imports.documentsCsv,
  }),
  (dispatch) => ({
    actions: bindActionCreators(actions, dispatch),
  })
)(DocumentsImport);
