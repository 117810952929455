import * as types from 'store/types/user';
import * as authTypes from 'store/types/auth';

const initialState = {
  id: null,
  isLoading: false,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case types.SET_INFO:
      return {
        ...state,
        ...action.payload
      };
    case types.SET_LOADING_STATUS:
      return {
        ...state,
        isLoading: action.status
      };
    case types.RESET:
    case authTypes.LOGOUT:
      return {
        ...initialState
      };
    default:
      return state;
  }
};
