import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { Modal } from 'antd';
import CreateProjectForm from './Form';

class CreateProjectModal extends PureComponent {
  static propTypes = {
    title: PropTypes.string,
    error: PropTypes.string,
    visible: PropTypes.bool,
    onClose: PropTypes.func,
    onSubmit: PropTypes.func,
    onClearError: PropTypes.func
  };

  static defaultProps = {
    title: '',
    error: '',
    visible: true,
    onClose: () => {},
    onClearError: () => {},
    onSubmit: () => {}
  };

  render() {
    const {
      visible,
      title,
      onClose,
      onClearError,
      error,
      onSubmit,
    } = this.props;

    return (
      <Modal
        visible={visible}
        title={title}
        width={400}
        centered
        footer={false}
        destroyOnClose
        onCancel={onClose}
      >
        <CreateProjectForm
          error={error}
          onClearError={onClearError}
          onSubmit={onSubmit}
        />
      </Modal>
    );
  }
}

export default CreateProjectModal;
