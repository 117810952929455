import * as types from 'store/types/imports';

// documents
export const uploadDocuments = (projectId, files) => ({
  type: types.UPLOAD_DOCUMENTS,
  projectId,
  files
});

export const setDocumentsUploadProgress = (payload) => ({
  type: types.SET_DOCUMENTS_UPLOAD_PROGRESS,
  payload,
});

export const setDocumentsUploadingStatus = (status) => ({
  type: types.SET_DOCUMENTS_UPLOADING_STATUS,
  status
});

export const resetDocuments = () => ({
  type: types.RESET_DOCUMENTS
});

// documents csv
export const uploadDocumentsCsv = (projectId, file) => ({
  type: types.UPLOAD_DOCUMENTS_CSV,
  projectId,
  file
});

export const setDocumentsCsvUploadingStatus = (status) => ({
  type: types.SET_DOCUMENTS_CSV_UPLOADING_STATUS,
  status
});

export const setDocumentsCsvErrors = (payload) => ({
  type: types.SET_DOCUMENTS_CSV_ERRORS,
  payload
});

export const resetDocumentsCsv = () => ({
  type: types.RESET_DOCUMENTS_CSV
});

// questions
export const uploadQuestionsCsv = (projectId, file) => ({
  type: types.UPLOAD_QUESTIONS_CSV,
  projectId,
  file
});

export const setQuestionsCsvUploadingStatus = (status) => ({
  type: types.SET_QUESTIONS_CSV_UPLOADING_STATUS,
  status
});

export const setQuestionsCsvErrors = (payload) => ({
  type: types.SET_QUESTIONS_CSV_ERRORS,
  payload
});

export const resetQuestionsCsv = () => ({
  type: types.RESET_QUESTIONS_CSV
});
