import React, { Component } from 'react';
import { Header } from '../common';
import Breadcrumbs from './../common/Breadcrumbs';
import styles from './styles.module.scss';

class MainTemplate extends Component {
  render() {
    return (
      <div className={styles.wrapper}>
        <Header />

        <Breadcrumbs />

        <div className={styles.content}>
          { this.props.children }
        </div>
      </div>
    );
  }
}

export default MainTemplate;
