import { prefix } from 'core/utils/string';

const userPrefix = prefix('users/');

export const GET_BY_ORGANIZATION_ID = userPrefix('GET_BY_ORGANIZATION_ID');
export const SET_USERS = userPrefix('SET_USERS');
export const LOGIN_AS = userPrefix('LOGIN_AS');
export const RESET_PASSWORD = userPrefix('RESET_PASSWORD');
export const SET_ERROR = userPrefix('SET_ERROR');
export const RESET = userPrefix('RESET');
export const SET_LOADING_STATUS = userPrefix('SET_LOADING_STATUS');
