import React, { Component } from 'react';
import { Button, Icon } from 'antd';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Redirect } from 'react-router-dom';
import PropTypes from 'prop-types';

import * as superAdminActions from 'store/actions/superAdmin';
import CreateSuperAdminForm from './CreateSuperAdminForm';
import AuthWrapper from 'components/AuthWrapper';
import styles from './styles.module.scss';

class CreateSuperAdmin extends Component {
  static propTypes = {
    superAdminActions: PropTypes.object,
    token: PropTypes.string,
  };

  state = {
    isStartScreen: true
  };

  onChangeScreen = () => {
    this.setState(({ isStartScreen }) => ({ isStartScreen: !isStartScreen }));
  };

  render() {
    const {
      error,
      superAdminActions,
      token
    } = this.props;
    const { isStartScreen } = this.state;

    if (token) {
      return <Redirect to="/" />;
    }

    return(
      <AuthWrapper title="Setup wizard">
        {
          isStartScreen ? <StartScreen onChangeScreen={this.onChangeScreen} /> :
            <CreateSuperAdminForm
              error={error}
              onClearError={superAdminActions.resetError}
              onSubmit={superAdminActions.createSuperAdmin}
              toPrevStep={this.onChangeScreen}
            />
        }
      </AuthWrapper>
    );
  }
}

const StartScreen = ({ onChangeScreen }) => {
  return(
    <div className={styles.startScreen}>
      <p>Welcome to the deepset labelling tool!</p>

      <p>
        The first step is to create a super admin user.
        The super admin has access to all data in the system and should be used for technical maintenance only.<br />
        <br />
        The following functionality is available to an superadmin:<br />

      </p>

      <ul className={styles.startScreen__featuresList}>
        <li>Overview of all organizations.</li>
        <li>Overview of all users in an organizations.</li>
        <li>Reset user credentials</li>
        <li>Login as an other user</li>
      </ul>

      <p>Please continue to create the super user.</p>

      <Button
        className={styles.startScreen__nextButton}
        type="primary"
        onClick={onChangeScreen}
      >
        <Icon type="arrow-right" />
      </Button>
    </div>
  );
};

const mapDispatchToProps = (dispatch) => ({
  superAdminActions: bindActionCreators(superAdminActions, dispatch)
});

const mapStateToProps = ({ superAdmin, auth }) => ({
  error: superAdmin.error,
  token: auth.token,
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CreateSuperAdmin);
