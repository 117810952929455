// token
export const setToken = (token) => {
  localStorage.setItem('dpst-token', token);
};

export const getToken = () => {
  return localStorage.getItem('dpst-token');
};

export const removeToken = () => {
  localStorage.removeItem('dpst-token');
};