import { all, put, takeEvery, takeLatest, call } from 'redux-saga/effects';
import { message } from 'antd';
import * as api from 'store/sagas/api';
import * as types from 'store/types/documents';
import * as actions from 'store/actions/documents';
import history from './../../history';
import links from 'config/links';

export function* getAll ({ projectId }) {
  yield put(actions.setLoadingStatus(true));

  try {
    // get documents with limit 1 to get documents total count
    const { pagination } = yield api.get(`/projects/${projectId}/documents`, { limit: 1 });

    // get documents with the limit
    const data = yield api.get(`/projects/${projectId}/documents`, { limit: pagination.count });

    yield put(actions.set(data));
  } catch (error) {
    yield call(history.push, links.projects);

    message.error(error.message);
  }

  yield put(actions.setLoadingStatus(false));
}

// export function* get () {
//   yield put(actions.setLoadingStatus(true));

//   try {
//     const filter = {
//       limit: 30,
//     };

//     const data = yield api.get('/documents', filter);
//     yield put(actions.set(data));
//   } catch (error) {
//     message.error(error.message);
//   }

//   yield put(actions.setLoadingStatus(false));
// }

export function* deleteDocument ({ id }) {
  try {
    yield api.del(`/documents/${id}`);

    yield put(actions.removeDocument(id));
  } catch (error) {
    message.error(error.message);
  }
}

export default function* () {
  yield all([
    // yield takeLatest(types.GET, get), // not used now
    yield takeLatest(types.GET_ALL, getAll),
    yield takeEvery(types.DELETE_DOCUMENT, deleteDocument),
  ]);
}
