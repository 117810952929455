import React, { Fragment, PureComponent } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import moment from "moment";
import { Table, Button, Empty, Popconfirm } from "antd";
import * as actions from "store/actions/questions";
import { Loader } from "components/common";
import AddQuestionModal from "components/modals/AddQuestion";
import styles from "./styles.module.scss";
import { Switch } from "antd";

const emptyTableDescription = (
  <Fragment>
    <span className="emptyTableText">
      This project doesn't have any standard questions.
    </span>
    <span>
      Please, use "Add question" button on top right to add a new question to
      the project.
    </span>
  </Fragment>
);

export class StandardQuestions extends PureComponent {
  constructor(props) {
    super(props);
    this.includeDocumentLevelQuestions.bind(this);

    this.state = {
      showDocumentLevelQuestions: false,
      isQuestionModalVisible: false,
      question: null,
    };
  }

  static propTypes = {
    questions: PropTypes.object,
    actions: PropTypes.object,
  };

  static defaultProps = {
    questions: {},
    actions: {},
  };

  openQuestionModal = () => {
    this.setState({
      isQuestionModalVisible: true,
    });
  };

  closeQuestionModal = () => {
    this.setState({
      question: null,
      isQuestionModalVisible: false,
    });
  };

  handleQuestionSubmit = (question) => {
    const { actions, match } = this.props;
    const questionBody = {
      ...question,
      isGlobalQuestion: true,
    };

    if (this.state.question) {
      actions.updateQuestion(this.state.question.id, questionBody);
    } else {
      questionBody.project_id = match.params.projectId;
      actions.createQuestion(questionBody);
    }

    this.closeQuestionModal();
  };

  handleQuestionEdit = (question) => () => {
    this.setState({
      question,
      isQuestionModalVisible: true,
    });
  };

  handleQuestionDelete = (question) => () => {
    this.props.actions.deleteQuestion(question.id);
  };

  renderColumns = () => {
    return [
      {
        title: "Question text",
        dataIndex: "text",
        key: "text",
        width: 700,
      },
      {
        title: "Category",
        dataIndex: "category",
        key: "category",
        align: "center",
      },
      {
        title: "Created",
        dataIndex: "createdAt",
        key: "createdAt",
        align: "center",
        render: (date) => moment(date).format("DD-MM-YYYY"),
      },
      {
        title: "Type",
        render: (field, row) =>
          row.document_id
            ? `Document ${row.document_id} attached`
            : "globally available",
      },
      {
        title: "Updated",
        dataIndex: "updatedAt",
        key: "updatedAt",
        align: "center",
        render: (date) => moment(date).format("DD-MM-YYYY"),
      },

      {
        title: "Actions",
        key: "action",
        align: "center",
        render: (el) => {
          return (
            <div className={styles.buttons}>
              <Button
                shape="circle"
                size="small"
                onClick={this.handleQuestionEdit(el)}
              >
                <i className="icon-edit" />
              </Button>

              <Popconfirm
                placement="leftTop"
                title="Are you sure to delete this standard question?"
                onConfirm={this.handleQuestionDelete(el)}
                okText="Yes"
                cancelText="No"
              >
                <Button shape="circle" size="small">
                  <i className="icon-delete" />
                </Button>
              </Popconfirm>
            </div>
          );
        },
        width: 120,
      },
    ];
  };
  fetchQuestions() {

    this.props.actions.getAll(
      this.props.match.params.projectId,
      this.state.showDocumentLevelQuestions
    );
  }
  componentDidMount() {
    this.fetchQuestions();
  }
  includeDocumentLevelQuestions(checked) {
    console.log('go', checked);
    this.setState({
      showDocumentLevelQuestions: checked,
    }, () => this.fetchQuestions());

  }
  renderBody() {
    const { entries, pagination, isLoading } = this.props.questions;

    if (!entries.length && isLoading) {
      return <Loader />;
    }
    return (
      <Loader loading={isLoading}>
        <Table
          bordered
          columns={this.renderColumns()}
          dataSource={entries.map((item) => ({
            ...item,
            key: item.id,
          }))}
          pagination={{
            pageSize: 30,
            total: pagination.count,
          }}
          locale={{
            emptyText: (
              <Empty
                image={Empty.PRESENTED_IMAGE_SIMPLE}
                description={emptyTableDescription}
              />
            ),
          }}
        />
      </Loader>
    );
  }
  render() {
    const { question, isQuestionModalVisible } = this.state;

    return (
      <div>
        <div className={styles.wrapper}>
          <div className={styles.title}>
            <h1 className="h2">Standard questions</h1>
            <p>
              <Switch
                defaultChecked={this.state.showDocumentLevelQuestions}
                onChange={this.includeDocumentLevelQuestions.bind(this)}
              ></Switch>
              &nbsp; Show document level questions
            </p>

            <Button type="primary" onClick={this.openQuestionModal}>
              Add question
            </Button>
          </div>
          {this.renderBody()}
        </div>
        {isQuestionModalVisible && (
          <AddQuestionModal
            {...(question ? { question } : {})}
            onSubmit={this.handleQuestionSubmit}
            onClose={this.closeQuestionModal}
          />
        )}
      </div>
    );
  }

  componentWillUnmount() {
    this.props.actions.reset();
  }
}

export default connect(
  ({ questions }) => ({ questions }),
  (dispatch) => ({ actions: bindActionCreators(actions, dispatch) })
)(StandardQuestions);
