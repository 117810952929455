import { all, put, call, select, takeEvery, takeLatest, delay } from 'redux-saga/effects';
import { message } from 'antd';
import { getRandomColor } from 'core/utils/color';
import { createLink } from 'core/utils/links';
import * as api from 'store/sagas/api';
import * as types from 'store/types/document';
import * as actions from 'store/actions/document';
import history from './../../history';
import { answerCategories } from 'core/constants/types';

export function* get ({ id }) {
  yield put(actions.setLoadingStatus(true));

  try {
    if (id) {
      const data = yield api.get(`/documents/${id}`);
      yield put(actions.set(data));
    }
  } catch (error) {
    message.error(error.message);
  }

  yield put(actions.setLoadingStatus(false));
}

export function* getAnswers ({ documentId }) {
  yield put(actions.toggleShowAllLabelsStatus());
  const { showAllLabels } = yield select(state => state.document);

  yield delay(500);

  yield put(actions.setLoadingStatus(true));

  try {
    const filter = {
      showAllLabels,
      limit: 10000
    };

    const { entries: answers } = yield api.get(`/documents/${documentId}/answers`, filter);

    yield put(actions.setAnswers(answers));
  } catch (error) {
    yield put(actions.toggleShowAllLabelsStatus());
    message.error(error.message);
  }

  yield put(actions.setLoadingStatus(false));
}

export function* getByOffset ({ projectId, offset }) {
  yield put(actions.setLoadingStatus(true));

  try {
    const filter = { offset, limit: 1 };
    const { entries } = yield api.get(`/projects/${ projectId }/documents`, filter);

    if (entries.length) {
      const data = yield api.get(`/documents/${entries[0].id}`);
      yield put(actions.set(data));

      // update page route
      history.push(createLink('document', { projectId, documentId: entries[0].id }));
    }
  } catch (error) {
    message.error(error.message);
  }

  yield put(actions.setLoadingStatus(false));
}

export function* updateField ({ field, value }) {
  const document = yield select(state => state.document.document);

  try {
    yield put(actions.updateField(field, value));

    yield api.put(`/documents/${document.id}`, { [field]: value });

  } catch (error) {
    yield put(actions.updateField(field, document[field]));
    message.error(error.message);
  }
}

export function* deleteLabeler () {
  const document = yield select(state => state.document.document);

  try {
    yield put(actions.updateField('labeler_id', null));

    yield api.del(`/documents/${document.id}/labeler`);

  } catch (error) {
    yield put(actions.updateField('labeler_id', document.labeler_id));
    message.error(error.message);
  }
}

export function* createQuestionWithAnswer ({ question, answer }) {
  const documentId = yield select(state => state.document.document.id);
  const projectId = yield select(state => state.document.document.projectId);

  yield put(actions.setQuestionLoadingStatus(true));
  try {
    const response = yield api.post(`/questions`, { document_id: documentId, project_id: projectId, ...question });
    const newQuestion = {
      ...response.data,
      color: getRandomColor()
    };

    yield put(actions.addQuestion(newQuestion));

    yield call(updateAnswer, newQuestion.id, answer);
  } catch (error) {
    message.error(error.message);
  }

  yield put(actions.setQuestionLoadingStatus(false));
}


export function* updateQuestion (id, question) {
  const document = yield select(state => state.document);

  try {
    yield put(actions.changeQuestion(id, question));

    yield api.put(`/questions/${id}`, question);
  } catch (error) {
    yield put(actions.set(document));
    message.error(error.message);
  }
}

export function* deleteQuestion (id) {
  const document = yield select(state => state.document);

  try {
    yield put(actions.removeQuestion(id));

    yield api.del(`/questions/${id}`);

  } catch (error) {
    yield put(actions.set(document));
    message.error(error.message);
  }
}

export function* deleteQuestionAnswers (id) {
  const { id: userId } = yield select(state => state.user);
  const document = yield select(state => state.document);
  const userAnswers = document.answers.filter(item => item.question_id === id && item.user_id === userId);
  try {
    if (userAnswers.length) {
      for (let answer of userAnswers) {
        yield call(deleteAnswer, answer.id);
      }
      message.success('All answers were removed.')
    } else {
      message.info('There are no answers to the question.')
    }
  } catch (error) {
    yield put(actions.set(document));
    message.error(error.message);
  }
}

export function* updateAnswer (questionId, data) {
  const documentId = yield select(state => state.document.document.id);

  try {
    const response = yield api.post(
      `/documents/${documentId}/answers`,
      { question_id: questionId, ...data }
    );

    yield put(actions.changeAnswer(questionId, response.item));

    // show massage only in it's negative answer (empty)
    if ([answerCategories.NOT_GIVEN, answerCategories.NOT_UNDERSTOOD].includes(data.answer_category)) {
      message.success('The answer was saved.')
    }
  } catch (error) {
    message.error(error.message);
  }
}

export function* deleteAnswer (id) {

  try {
    yield api.del(`/answers/${id}`);

    yield put(actions.removeAnswer(id));
  } catch (error) {
    message.error(error.message);
  }
}


export default function* () {
  yield all([
    yield takeLatest(types.GET, get),
    yield takeLatest(types.GET_ANSWERS, getAnswers),
    yield takeLatest(types.GET_BY_OFFSET, getByOffset),
    yield takeEvery(types.UPDATE_FIELD_LIVE, updateField),
    yield takeEvery(types.DELETE_LABELER, deleteLabeler),
    yield takeEvery(types.CREATE_QUESTION_WITH_ANSWER, createQuestionWithAnswer),
    yield takeEvery(types.UPDATE_QUESTION, ({ id, question }) => updateQuestion(id, question)),
    yield takeEvery(types.DELETE_QUESTION, ({ id }) => deleteQuestion(id)),
    yield takeEvery(types.DELETE_QUESTION_ANSWERS, ({ id }) => deleteQuestionAnswers(id)),
    yield takeEvery(types.UPDATE_ANSWER, ({ questionId, data }) => updateAnswer(questionId, data)),
    yield takeEvery(types.DELETE_ANSWER, ({ id }) => deleteAnswer(id)),
  ]);
}
