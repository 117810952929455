import React, { PureComponent } from 'react';
import { Tabs } from 'antd';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import Loader from 'components/common/Loader';
import ProjectTab from 'components/Organization/ProjectTab';
import UserTab from 'components/Organization/UserTab';
import * as actions from 'store/actions/organizations';
import styles from './styles.module.scss';

const { TabPane } = Tabs;

class Organization extends PureComponent {
  static propTypes = {
    activeOrganization: PropTypes.object,
    getOrganization: PropTypes.func
  };

  static defaultProps = {
    activeOrganization: {},
    getOrganization: () => {}
  };

  render() {
    const {
      activeOrganization,
      isLoading,
      match
    } = this.props;

    const organizationId = match.params.id;

    if (isLoading) {
      return <Loader />;
    }

    return (
      <div className={styles.wrapper}>
        <div className={styles.title}>
          <h1 className="h2">Organization ({ activeOrganization.name })</h1>
        </div>

        <Tabs tabPosition="top">
          <TabPane tab="Projects" key="1">
            <ProjectTab organizationId={organizationId} />
          </TabPane>

          <TabPane tab="Users" key="2">
            <UserTab organizationId={organizationId} />
          </TabPane>
        </Tabs>
      </div>
    );
  }
  
  componentDidMount() {
    const {
      match,
      getOrganization
    } = this.props;

    getOrganization(match.params.id);
  }
}

const mapStateToProps = ({ organizations }) => ({
  activeOrganization: organizations.activeOrganization,
  isLoading: organizations.isLoading
});

const mapDispatchToProps = (dispatch) => ({
  getOrganization: (id) => dispatch(actions.getOrganization(id))
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Organization);