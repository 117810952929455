import { all, put, select, takeEvery, takeLatest } from 'redux-saga/effects';
import { message } from 'antd';
import * as api from 'store/sagas/api';
import * as types from 'store/types/questions';
import * as actions from 'store/actions/questions';


export function* getAllQuestions (project_id, includeDocumentLevelQuestions = true) {
  yield put(actions.setLoadingStatus(true));

  try {
    const filter = {
      includeDocumentLevelQuestions,
      limit: 1000,
    };

    const data = yield api.get('/questions', {
      ...filter,
      project_id,
    });

    yield put(actions.setAll(data));
  } catch (error) {
    message.error(error.message);
  }

  yield put(actions.setLoadingStatus(false));
}

export function* get () {
  yield put(actions.setLoadingStatus(true));

  try {
    const filter = {
      includeDocumentLevelQuestions: true,
      limit: 30,
    };

    const data = yield api.get('/questions', filter);
    yield put(actions.set(data));
  } catch (error) {
    message.error(error.message);
  }

  yield put(actions.setLoadingStatus(false));
}

export function* getMore () {
  const questions = yield select(state => state.questions);

  yield put(actions.setLoadingStatus(true));

  try {
    const filter = {
      includeDocumentLevelQuestions: true,
      limit: 30,
      offset: questions.entries.length
    };

    const data = yield api.get('/questions', filter);
    yield put(actions.setMore(data));
  } catch (error) {
    message.error(error.message);
  }

  yield put(actions.setLoadingStatus(false));
}

export function* createQuestion ({ question }) {
  yield put(actions.setLoadingStatus(true));
  try {
    const response = yield api.post(`/questions`, question);

    yield put(actions.addQuestion(response.data));
  } catch (error) {
    message.error(error.message);
  }
  yield put(actions.setLoadingStatus(false));
}

export function* updateQuestion ({ id, question }) {
  const questions = yield select(state => state.questions.entries);

  try {
    yield put(actions.changeQuestion(id, question));

    yield api.put(`/questions/${id}`, { ...question });
  } catch (error) {
    yield put(actions.set(questions));
    message.error(error.message);
  }
}

export function* deleteQuestion ({ id }) {

  try {
    yield put(actions.removeQuestion(id));

    yield api.del(`/questions/${id}`);

  } catch (error) {
    yield put(actions.get());
    message.error(error.message);
  }
}

export default function* () {
  yield all([
    yield takeLatest(types.GET_ALL, ({ projectId, includeDocumentLevelQuestions }) => getAllQuestions(projectId, includeDocumentLevelQuestions)),
    yield takeLatest(types.GET, get),
    yield takeLatest(types.GET_MORE, getMore),
    yield takeEvery(types.CREATE_QUESTION, createQuestion),
    yield takeEvery(types.UPDATE_QUESTION, updateQuestion),
    yield takeEvery(types.DELETE_QUESTION, deleteQuestion),
  ]);
}
