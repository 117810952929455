import * as types from 'store/types/documents';

export const get = () => ({
  type: types.GET
});

export const set = (payload) => ({
  type: types.SET,
  payload,
});

export const getAll = (projectId) => ({
  type: types.GET_ALL,
  projectId
});

export const deleteDocument = (id) => ({
  type: types.DELETE_DOCUMENT,
  id
});

export const removeDocument = (id) => ({
  type: types.REMOVE_DOCUMENT,
  id
});

export const setLoadingStatus = (status) => ({
  type: types.SET_LOADING_STATUS,
  status
});

export const reset = () => ({
  type: types.RESET,
});
