import * as types from 'store/types/questions';

export const get = () => ({
  type: types.GET
});

export const set = (payload) => ({
  type: types.SET,
  payload
});

export const getMore = () => ({
  type: types.GET_MORE
});

export const setMore = (payload) => ({
  type: types.SET_MORE,
  payload
});

export const getAll = (projectId, includeDocumentLevelQuestions = true) => ({
  type: types.GET_ALL,
  projectId,
  includeDocumentLevelQuestions
});

export const setAll = (payload) => ({
  type: types.SET_ALL,
  payload
});

export const setActiveTab = (tab) => ({
  type: types.SET_ACTIVE_TAB,
  tab
});

export const createQuestion = (question) => ({
  type: types.CREATE_QUESTION,
  question,
});

export const addQuestion = (question) => ({
  type: types.ADD_QUESTION,
  question
});

export const updateQuestion = (id, question) => ({
  type: types.UPDATE_QUESTION,
  id,
  question
});

export const changeQuestion = (id, question) => ({
  type: types.CHANGE_QUESTION,
  id,
  question
});

export const deleteQuestion = (id) => ({
  type: types.DELETE_QUESTION,
  id
});

export const removeQuestion = (id) => ({
  type: types.REMOVE_QUESTION,
  id
});

export const setLoadingStatus = (status) => ({
  type: types.SET_LOADING_STATUS,
  status
});

export const reset = () => ({
  type: types.RESET,
});
