import * as types from 'store/types/imports';

const initialState = {
  documents: {
    // txt/pdf
    progress: {
      files: [],
      totalSize: 0,
      uploadedSize: 0,
      completedFiles: 0,
    },
    isUploading: false,
  },
  documentsCsv: {
    file: null,
    errors: [],
    isUploading: false,
  },
  questionsCsv: {
    file: null,
    errors: [],
    isUploading: false,
  },
};

export default (state = initialState, action) => {
  switch (action.type) {
    case types.UPLOAD_DOCUMENTS:
      return {
        ...state,
        documents: {
          ...state.documents,
          progress: {
            ...state.documents.progress,
            files: action.files,
            totalSize: action.files.reduce((acc, { size }) => acc + size, 0)
          },
        }
      };
    case types.SET_DOCUMENTS_UPLOAD_PROGRESS:
      return {
        ...state,
        documents: {
          ...state.documents,
          progress: {
            ...state.documents.progress,
            ...action.payload,
          }
        }
      };
    case types.SET_DOCUMENTS_UPLOADING_STATUS:
      return {
        ...state,
        documents: {
          ...state.documents,
          isUploading: action.status,
        }
      };
    case types.RESET_DOCUMENTS:
      return {
        ...state,
        documents: {
          ...initialState.documents,
        },
      };

    // documents csv
    case types.UPLOAD_DOCUMENTS_CSV:
      return {
        ...state,
        documentsCsv: {
          ...state.documentsCsv,
          file: action.file,
        }
      };
    case types.SET_DOCUMENTS_CSV_UPLOADING_STATUS:
      return {
        ...state,
        documentsCsv: {
          ...state.documentsCsv,
          isUploading: action.status,
        }
      };
    case types.SET_DOCUMENTS_CSV_ERRORS:
      return {
        ...state,
        documentsCsv: {
          ...state.documentsCsv,
          errors: action.payload,
        }
      };
    case types.RESET_DOCUMENTS_CSV:
      return {
        ...state,
        documentsCsv: {
          ...initialState.documentsCsv,
        },
      };

    // questions csv
    case types.UPLOAD_QUESTIONS_CSV:
      return {
        ...state,
        questionsCsv: {
          ...state.questionsCsv,
          file: action.file,
        }
      };
    case types.SET_QUESTIONS_CSV_UPLOADING_STATUS:
      return {
        ...state,
        questionsCsv: {
          ...state.questionsCsv,
          isUploading: action.status,
        }
      };
    case types.SET_QUESTIONS_CSV_ERRORS:
      return {
        ...state,
        questionsCsv: {
          ...state.questionsCsv,
          errors: action.payload,
        }
      };
    case types.RESET_QUESTIONS_CSV:
      return {
        ...state,
        questionsCsv: {
          ...initialState.questionsCsv,
        },
      };

    default:
      return state;
  }
};
