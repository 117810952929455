import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import * as actions from 'store/actions/projects';
import Loader from 'components/common/Loader';
import ProjectTable from 'components/shared/ProjectTable';

class ProjectTab extends PureComponent {
  static propTypes = {
    projects: PropTypes.array,
    getProjects: PropTypes.func,
    isLoading: PropTypes.bool
  };

  static defaultProps = {
    projects: [],
    getProjects: () => {},
  };

  render() {
    const {
      projects,
      isLoading,
    } = this.props;

    if (!projects.length && isLoading) {
      return <Loader />;
    }

    return (
      <Loader loading={isLoading}>
        <ProjectTable
          projects={projects}
          emptyTableDescription={<span>This organization doesn't have any projects.</span>}
        />
      </Loader>
    );
  }

  componentDidMount() {
    const {
      organizationId,
      getProjects
    } = this.props;

    getProjects(organizationId);
  }
}

const mapStateToProps = ({ projects }) => ({
  projects: projects.entries,
  isLoading: projects.isLoading
});

const mapDispatchToProps = (dispatch) => ({
  getProjects: (organizationId) => dispatch(actions.get(organizationId)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ProjectTab);