import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Row, Col, Pagination, Button, Empty, Alert, Modal, Radio } from 'antd';
import { SwitchButton, Loader } from 'components/common';
import { documentStates, annotationModes, answerCategories, answerCategoriesText } from 'core/constants/types';
import links from 'config/links';
import AddQuestionModal from 'components/modals/AddQuestion';
import Questions from './components/Questions';
import TextSelectionArea from './components/TextSelectionArea';
import { createLink } from 'core/utils/links';
import * as questionsActions from 'store/actions/questions';
import * as documentActions from 'store/actions/document';
import * as activeQuestionActions from 'store/actions/activeQuestion';
import styles from './styles.module.scss';

export class Document extends PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      question: null,
      questionAnswerCategory: answerCategories.SHORT,
      isQuestionModeActive: false,
      isQuestionModalVisible: false,
    };
  }

  static propTypes = {
    history: PropTypes.object,
    match: PropTypes.object,
    user: PropTypes.object,
    data: PropTypes.object,
    activeProject: PropTypes.object,
    activeQuestion: PropTypes.object,
    questions: PropTypes.object,
    actions: PropTypes.object,
    documentActions: PropTypes.object,
    questionsActions: PropTypes.object,
    activeQuestionActions: PropTypes.object,
  }

  questionAnswerCategoryChange = ({ target: { value } }) => {
    this.setState({ questionAnswerCategory: value });
  }

  showAnswerCategoryModal = (activeQuestionId, answerData) => {
    Modal.confirm({
      className: styles.answerCategoryModal,
      centered: true,
      width: 570,
      title: 'Select the answer category:',
      content: (
        <Radio.Group onChange={this.questionAnswerCategoryChange} defaultValue={answerCategories.SHORT}>
          <Radio
            value={answerCategories.SHORT}
            className={styles.answerCategoryRadioOption}
          >{answerCategoriesText[answerCategories.SHORT]}</Radio>
          <Radio
            value={answerCategories.LONG}
            className={styles.answerCategoryRadioOption}
          >{answerCategoriesText[answerCategories.LONG]}</Radio>
          <Radio
            value={answerCategories.YES}
            className={styles.answerCategoryRadioOption}
          >{answerCategoriesText[answerCategories.YES]}</Radio>
          <Radio
            value={answerCategories.NO}
            className={styles.answerCategoryRadioOption}
          >{answerCategoriesText[answerCategories.NO]}</Radio>
          <Radio
            value={answerCategories.OTHER}
            className={styles.answerCategoryRadioOption}
          >{answerCategoriesText[answerCategories.OTHER]}</Radio>
        </Radio.Group>
      ),
      okText: "Save",
      onOk: () => {
        this.setState(({ questionAnswerCategory }) => {
          this.props.documentActions.updateAnswer(
            activeQuestionId,
            { ...answerData, answer_category: questionAnswerCategory }
          );
          return { questionAnswerCategory: answerCategories.SHORT }
        })
      },
      onCancel: () => {
        this.setState({ questionAnswerCategory: answerCategories.SHORT })
      }
    });
  }

  handleAnswerChange = (data) => {
    const { activeProject, activeQuestion, documentActions, activeQuestionActions } = this.props;

    // update passages only if a question selected
    if (activeQuestion.id !== null) {
      if (activeProject.annotation_mode === annotationModes.ANSWER_CATEGORY_MODE) {
        this.showAnswerCategoryModal(activeQuestion.id, data)
      } else {
        documentActions.updateAnswer(activeQuestion.id, data);
      }
      // and then deselect the question
      activeQuestionActions.reset();
    }
  }

  // document questions handlers
  closeQuestionModal = () => {
    this.setState({
      isQuestionModalVisible: false
    });
  }

  handleQuestionSubmit = (question) => {
    this.props.documentActions.updateQuestion(this.state.question.id, question);

    this.closeQuestionModal();
  }

  handleQuestionClick = (question, index) => {
    this.props.activeQuestionActions.set(question, index);
  }

  handleDocumentQuestionEdit = (question) => {
    this.setState({ question, isQuestionModalVisible: true });
  }

  handleDocumentQuestionDelete = (question) => {
    this.props.documentActions.deleteQuestion(question.id);


    if (question.id === this.props.activeQuestion.id) {
      this.props.activeQuestionActions.reset();
    }
  }

  handleQuestionWithAnswerCreate = (question, answer) => {
    this.props.documentActions.createQuestionWithAnswer(question, answer);
    this.handleAddQuestionClose();
  }

  handleAddQuestionOpen = () => {
    this.setState({
      isQuestionModeActive: true
    });

    this.props.activeQuestionActions.reset();
  }

  handleAddQuestionClose = () => {
    this.setState({
      isQuestionModeActive: false
    });
  }
  // document questions handlers - end

  handlePageChange = (page) => {
    const {
      history,
      match: { params },
      documentActions,
      data: { pagination }
    } = this.props;

    // if page change is next/prev
    if (Math.abs(page - pagination.currentPosition) === 1) {
      let nextDocumentId = null;

      if (page > pagination.currentPosition) {
        nextDocumentId = pagination.next;
      } else {
        nextDocumentId = pagination.prev;
      }

      documentActions.get(nextDocumentId);
      history.push(createLink('document', {
        projectId: params.projectId,
        documentId: nextDocumentId,
      }));

    } else {
      // otherwise, get document by offset
      documentActions.getByOffset(params.projectId, page - 1);
      return;
    }

    this.setState({ isQuestionModeActive: false });
  };

  handleKeyPress = (e) => {
    const { keyCode, target } = e;
    const { data, user, questions, activeQuestion } = this.props;
    const { isQuestionModalVisible } = this.state;
    const { pagination } = data;
    const currentQuestions = questions.entriesCategoryMap[questions.activeTab];

    // ignore if target points to form elements or modal is opened
    if (target.tagName === 'INPUT' || target.tagName === 'TEXTAREA' || isQuestionModalVisible) return;

    // if user have no permission to edit the document
    // and pressed one of the buttons that controls questions change - ignore it
    if (((data.document.labeler_id && (data.document.labeler_id !== user.id)) ||
      (data.document.status === documentStates.DONE)) &&
      [38, 40, 49, 50, 51, 52, 53, 54, 55, 56, 57, ...questions.categoriesKeys].includes(keyCode)) {
      return;
    }

    // questions tabs hotkeys
    if (questions.categoriesKeys.includes(keyCode)) {
      const index = questions.categoriesKeys.indexOf(keyCode);
      this.handleTabChange(questions.availableCategories[index]);
    }


    switch (keyCode) {
      // left
      case 37:
        if (pagination.currentPosition - 1) {
          this.handlePageChange(pagination.currentPosition - 1);
        }
        break;
      // right
      case 39:
        if (pagination.currentPosition < pagination.length) {
          this.handlePageChange(pagination.currentPosition + 1);
        }
        break;

      // up
      case 38: {
        if (activeQuestion.index > 0) {
          e.preventDefault(); // prevent default page scrolling

          const newIndex = activeQuestion.index - 1;
          const question = currentQuestions[newIndex];

          this.props.activeQuestionActions.set(
            question,
            newIndex
          );
        }
        break;
      }

      // down
      case 40: {
        if (activeQuestion.index !== null && activeQuestion.index < currentQuestions.length - 1) {
          e.preventDefault(); // prevent default page scrolling

          const newIndex = activeQuestion.index + 1;
          const question = currentQuestions[newIndex];

          this.props.activeQuestionActions.set(
            question,
            newIndex
          );
        }
        break;
      }

      // 1 - 9 numbers
      case 49:
      case 50:
      case 51:
      case 52:
      case 53:
      case 54:
      case 55:
      case 56:
      case 57: {
        const index = keyCode - 49;
        const tabQuestions = questions.entriesCategoryMap[questions.activeTab];
        const question = tabQuestions && index <= tabQuestions.length - 1
          ? tabQuestions[index]
          : null;

        if (question) {
          this.props.activeQuestionActions.set(
            question,
            index
          );
        }
        break;
      }
      default:
        return;
    }
  }

  handleTabChange = (tab) => {
    this.props.questionsActions.setActiveTab(tab);

    // if tab was changed - close custom mode and reset active question
    this.handleAddQuestionClose();
    this.props.activeQuestionActions.reset();
  }

  handleDocumentSearch = () => {
    this.setState({ isQuestionModeActive: false });
  }

  handleDocumentStateChange = (value) => {
    const { labeler_id } = this.props.data.document;
    const status = value === true
      ? documentStates.DONE
      : labeler_id === null ? documentStates.NEW : documentStates.INPROGRESS;

    this.props.documentActions.updateFieldLive('status', status);
  }

  componentDidMount () {
    const {
      match: { params },
      // documents,
      documentActions,
      questionsActions
    } = this.props;

    // if doucments list not exist yet - get all documents (it's needed to have ability to find id of document by index)
    // if (!documents.pagination.count) {
    //   this.props.documentsActions.getAll();
    // }

    questionsActions.getAll(params.projectId, false);
    documentActions.get(params.documentId);

    window.addEventListener('keydown', this.handleKeyPress);
  }

  render() {
    const {
      history,
      data,
      questions,
      activeQuestion,
      activeProject,
      documentActions,
    } = this.props;

    const isDocumentDone = data.document.status === documentStates.DONE;
    const canEdit = !isDocumentDone;
    const { isQuestionModeActive, isQuestionModalVisible, question } = this.state;
    const questionsColorsMap = questions.all
      .reduce((acc, item) => { acc[item.id] = item.color; return acc; }, {});
    const defaultSet = data.answers.map(item => ({
      ...item,
      ...(questionsColorsMap.hasOwnProperty(item.question_id)
        ? { color: questionsColorsMap[item.question_id] } : {})
    }));
    const documentQuestions = [...questions.all].splice(questions.entries.length);
    const answeredQuestionsIds = data.answers.map(i => i.question_id);

    if (data.isLoading) {
      return <Loader />;
    }

    if (!data.document.id) {
      return (
        <Empty
          image={Empty.PRESENTED_IMAGE_SIMPLE}
          description="No document available, if you want to add a document please click the button below"
        >
          <Button
            type="primary"
            onClick={() => this.props.history.push(links.projects)}
          >Manage documents</Button>
        </Empty>
      );
    }

    return (
      <Loader loading={questions.isLoading || data.isLoading} size={30}>
        <div className={styles.wrapper}>

          <Row
            className={styles.controls}
            type="flex"
            align="middle"
            justify="space-between"
          >
            <div className={styles.switchButtonWrapper}>
              <SwitchButton
                text="mark document as done"
                className={styles.switchButton}
                checked={data.document.status === documentStates.DONE}
                onChange={this.handleDocumentStateChange}
              />

              <SwitchButton
                text="show labels of all users"
                className={styles.switchButton}
                checked={data.showAllLabels}
                onChange={() => documentActions.getAnswers(data.document.id)}
              />
            </div>

            <div className={styles.paginationWrapper}>
              <Pagination
                simple
                defaultCurrent={data.pagination.currentPosition}
                current={data.pagination.currentPosition}
                pageSize={1}
                total={data.pagination.length}
                onChange={this.handlePageChange}
              />
              <Button
                icon="select"
                type="primary"
                size="small"
                onClick={() => history.push(createLink('project', { projectId: data.document.project_id }))}
              />
            </div>

          </Row>

          {
            isDocumentDone && (
              <Alert
                className={styles.alert}
                message="This document is done, thus it cannot be changed anymore. Please open it again on the topleft if needed."
                type="warning"
                showIcon
              />
            )
          }

          <Loader loading={data.isQuestionLoading}>
            <Row gutter={24}>
              <Col xs={{ span: 24 }} sm={{ span: 9 }}>
                <div className={styles.questions}>
                  <Questions
                    title="Questions"
                    disabled={!canEdit}
                    itemsMap={questions.entriesCategoryMap}
                    customItems={documentQuestions}
                    maxListHeight="800px"
                    activeId={activeQuestion.id}
                    activeTab={questions.activeTab}
                    answeredQuestionsIds={answeredQuestionsIds}
                    onTabChange={this.handleTabChange}
                    onSelect={this.handleQuestionClick}
                    onEdit={this.handleDocumentQuestionEdit}
                    onDelete={this.handleDocumentQuestionDelete}
                    onAnswer={documentActions.updateAnswer}
                    onRemoveAnswers={documentActions.deleteQuestionAnswers}

                    isCustomModeActive={isQuestionModeActive}
                    onCustomModeOpen={this.handleAddQuestionOpen}
                    onCustomModeClose={this.handleAddQuestionClose}
                  />
                </div>

              </Col>
              <Col xs={{ span: 24 }} sm={{ span: 15 }}>
                <TextSelectionArea
                  editable={canEdit}
                  defaultSet={defaultSet}
                  document={data.document}
                  answers={data.answers}
                  question={activeQuestion}
                  maxTextHeight="900px"
                  answerWithCategoryMode={activeProject.annotation_mode === annotationModes.ANSWER_CATEGORY_MODE}
                  createQuestionAvailable={isQuestionModeActive}
                  onSearch={this.handleDocumentSearch}
                  onSelect={this.handleAnswerChange}
                  onSelectionDelete={documentActions.deleteAnswer}
                  onQuestionWithAnswerCreate={this.handleQuestionWithAnswerCreate}
                />
              </Col>

            </Row>
          </Loader>

          {
            // edit custom question modal
            isQuestionModalVisible && (
              <AddQuestionModal
                {...(question ? { question } : {})}
                isDocumentQuestion={true}
                onSubmit={this.handleQuestionSubmit}
                onClose={this.closeQuestionModal}
              />
            )
          }
        </div>
      </Loader>
    );
  }

  componentWillUnmount () {
    this.props.questionsActions.reset();
    this.props.documentActions.reset();
    this.props.activeQuestionActions.reset();

    window.removeEventListener('keydown', this.handleKeyPress);
  }
}

export default connect(
  state => ({
    user: state.user,
    activeProject: state.projects.activeProject,
    questions: state.questions,
    data: state.document,
    activeQuestion: state.activeQuestion,
  }),
  dispatch => ({
    questionsActions: bindActionCreators(questionsActions, dispatch),
    documentActions: bindActionCreators(documentActions, dispatch),
    activeQuestionActions: bindActionCreators(activeQuestionActions, dispatch),
  })
)(Document);
