import { prefix } from 'core/utils/string';

const questionsPrefix = prefix('questions/');

export const GET = questionsPrefix('GET');
export const SET = questionsPrefix('SET');
export const GET_ALL = questionsPrefix('GET_ALL');
export const SET_ALL = questionsPrefix('SET_ALL');

export const GET_MORE = questionsPrefix('GET_MORE');
export const SET_MORE = questionsPrefix('SET_MORE');

export const CREATE_QUESTION = questionsPrefix('CREATE_QUESTION'); // for saga action
export const ADD_QUESTION = questionsPrefix('ADD_QUESTION');

export const UPDATE_QUESTION = questionsPrefix('UPDATE_QUESTION'); // for saga action
export const CHANGE_QUESTION = questionsPrefix('CHANGE_QUESTION');

export const DELETE_QUESTION = questionsPrefix('DELETE_QUESTION'); // for saga action
export const REMOVE_QUESTION = questionsPrefix('REMOVE_QUESTION');

export const SET_ACTIVE_TAB = questionsPrefix('SET_ACTIVE_TAB');

export const SET_LOADING_STATUS = questionsPrefix('SET_LOADING_STATUS');

export const RESET = questionsPrefix('RESET');
