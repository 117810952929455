import React, { PureComponent } from "react";
import { Link, withRouter } from "react-router-dom";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { Menu, Icon } from "antd";
import cn from "classnames";
import * as actions from "store/actions/projects";
import { createLink, getParam } from "core/utils/links";
import links from "config/links";
import roles from "core/constants/roles";

import styles from "./styles.module.scss";

const { SubMenu } = Menu;

class NavMenu extends PureComponent {
  static propTypes = {
    location: PropTypes.object,
    projects: PropTypes.object,
    user: PropTypes.object,
    setActiveProject: PropTypes.func,
  };

  handleProjectChange = (project) => () => {
    this.props.setActiveProject(project);
  };

  componentDidUpdate(prevProps) {
    const projectId = getParam("projects", "projectId");
    const { projects } = this.props;
    const noProject = { id: null, name: null };

    // projects are loaded and no active project is set
    if (projects.entries.length && !projects.activeProject.id && projectId) {
      const newActiveProject = projects.entries.find(
        (item) => item.id === +projectId
      );
      const activeProject = newActiveProject || noProject;

      this.props.setActiveProject(activeProject);
    }

    // no selected project
    if (projects.activeProject.id && !projectId) {
      this.props.setActiveProject(noProject);
    }
  }

  render() {
    const {
      projects: { entries: projects, activeProject },
      user,
      location,
    } = this.props;
    const currentMenuItem = location.pathname;
    const hasActiveProject = !!activeProject.id;
    const linkToQuestions = hasActiveProject
      ? createLink("questions", { projectId: activeProject.id })
      : "/projects#1";
    const linkToAnswers = hasActiveProject
      ? createLink("answers", { projectId: activeProject.id })
      : "/projects#2";
    const linkToDocuments = hasActiveProject
      ? createLink("project", { projectId: activeProject.id })
      : "/projects#3";
    const linkToDocumentsImport = hasActiveProject
      ? createLink("importDocuments", { projectId: activeProject.id })
      : "/projects#4";
    const linkToQuestionsImport = hasActiveProject
      ? createLink("importQuestions", { projectId: activeProject.id })
      : "/projects#5";

    return (
      <div className={styles.navMenuWrapper}>
        <Menu
          style={{ backgroundColor: "transparent" }}
          className={cn("container", styles.navMenu)}
          selectedKeys={[currentMenuItem]}
          mode="horizontal"
          theme="dark"
        >
          <SubMenu
            title={
              <span className="submenu-title-wrapper">
                <Icon type="project" />

                {activeProject.id ? activeProject.name : "All Projects"}

                <Icon className={styles.downArrow} type="down" />
              </span>
            }
          >
            <Menu.Item
              key={links.projects}
              onClick={this.handleProjectChange({ id: null, name: null })}
            >
              <Link to={links.projects}>All Projects</Link>
            </Menu.Item>

            {projects.map((item) => (
              <Menu.Item key={item.id} onClick={this.handleProjectChange(item)}>
                <Link to={createLink("project", { projectId: item.id })}>
                  {item.name}
                </Link>
              </Menu.Item>
            ))}
          </SubMenu>

          <Menu.Item key={linkToDocuments} disabled={!hasActiveProject}>
            <Link to={linkToDocuments}>
              <Icon type="book" />
              Documents
            </Link>
          </Menu.Item>

          <Menu.Item key={linkToQuestions} disabled={!hasActiveProject}>
            <Link to={linkToQuestions}>
              <Icon type="question" />
              Questions
            </Link>
          </Menu.Item>

        <SubMenu
            title={
              <span className="submenu-title-wrapper">
                <Icon type="import" />
                Import
                <Icon className={styles.downArrow} type="down" />
              </span>
            }
            disabled={!hasActiveProject}
          >
            <Menu.Item key="import-documents">
              <Link to={linkToDocumentsImport}>Documents</Link>
            </Menu.Item>
            <Menu.Item key="import-questions">
              <Link to={linkToQuestionsImport}>Questions</Link>
            </Menu.Item>
          </SubMenu>
          <Menu.Item key={linkToAnswers} disabled={!hasActiveProject}>
            <Link to={linkToAnswers}>
              <Icon type="export" />
              Export Labels
            </Link>
          </Menu.Item>

          {user.role === roles.ADMIN && (
            <Menu.Item
              key={links.userManagement}
              style={{ marginLeft: "auto" }}
            >
              <Link to={links.userManagement}>
                <Icon type="team" />
                User management
              </Link>
            </Menu.Item>
          )}
        </Menu>
      </div>
    );
  }
}

export default withRouter(
  connect(
    (state) => ({
      user: state.user,
      projects: state.projects,
    }),
    (dispatch) => ({
      setActiveProject: (project) =>
        dispatch(actions.setActiveProject(project)),
    })
  )(NavMenu)
);
