import { prefix } from 'core/utils/string';

const organizationsPrefix = prefix('organizations/');

export const GET = organizationsPrefix('GET');
export const SET = organizationsPrefix('SET');
export const DELETE = organizationsPrefix('DELETE');
export const DELETE_FROM_STATE = organizationsPrefix('DELETE_FROM_STATE');
export const EDIT = organizationsPrefix('EDIT');
export const UPDATE_ORGANIZATION = organizationsPrefix('UPDATE_ORGANIZATION');
export const GET_ORGANIZATION = organizationsPrefix('GET_ORGANIZATION');
export const SET_ORGANIZATION = organizationsPrefix('SET_ORGANIZATION');

export const SET_LOADING_STATUS = organizationsPrefix('SET_LOADING_STATUS');
export const RESET = organizationsPrefix('RESET');
export const SET_ERROR = organizationsPrefix('SET_ERROR');
export const RESET_ERROR = organizationsPrefix('RESET_ERROR');