import { prefix } from 'core/utils/string';

const documentPrefix = prefix('document/');

export const GET = documentPrefix('GET');
export const GET_BY_OFFSET = documentPrefix('GET_BY_OFFSET');
export const SET = documentPrefix('SET');

export const UPDATE_FIELD_LIVE = documentPrefix('UPDATE_FIELD_LIVE');
export const UPDATE_FIELD = documentPrefix('UPDATE_FIELD');

export const DELETE_LABELER = documentPrefix('DELETE_LABELER');

export const CREATE_QUESTION_WITH_ANSWER = documentPrefix('CREATE_QUESTION_WITH_ANSWER'); // for saga action
export const ADD_QUESTION = documentPrefix('ADD_QUESTION');

export const UPDATE_QUESTION = documentPrefix('UPDATE_QUESTION'); // for saga action
export const CHANGE_QUESTION = documentPrefix('CHANGE_QUESTION');
export const DELETE_QUESTION_ANSWERS = documentPrefix('DELETE_QUESTION_ANSWERS'); // for saga action
export const DELETE_QUESTION = documentPrefix('DELETE_QUESTION'); // for saga action
export const REMOVE_QUESTION = documentPrefix('REMOVE_QUESTION');

export const GET_ANSWERS = documentPrefix('GET_ANSWERS');
export const SET_ANSWERS = documentPrefix('SET_ANSWERS');
export const UPDATE_ANSWER = documentPrefix('UPDATE_ANSWER'); // for saga action
export const CHANGE_ANSWER = documentPrefix('CHANGE_ANSWER');
export const TOGGLE_SHOW_ALL_LABELS_STATUS = documentPrefix('TOGGLE_SHOW_ALL_LABELS_STATUS');

export const DELETE_ANSWER = documentPrefix('DELETE_ANSWER'); // for saga action
export const REMOVE_ANSWER = documentPrefix('REMOVE_ANSWER');

export const SET_LOADING_STATUS = documentPrefix('SET_LOADING_STATUS');
export const SET_QUESTION_LOADING_STATUS = documentPrefix('SET_QUESTION_LOADING_STATUS');

export const RESET = documentPrefix('RESET');
